<template>
  <svg class="w-56" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 716.68 70.92">
    <defs>
      <linearGradient id="c" x1="646.51" x2="617.49" y1="6.51" y2="63" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#771712"/>
        <stop offset="1" stop-color="#a91314"/>
      </linearGradient>
      <linearGradient id="a" x1="708.41" x2="711.93" y1="15.67" y2="53.89" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#e40e20"/>
        <stop offset="1" stop-color="#a91314"/>
      </linearGradient>
      <linearGradient id="d" x1="652.4" x2="671.66" y1="42.1" y2="42.1" xlink:href="#a"/>
      <linearGradient id="e" x1="661" x2="683.53" y1="21.15" y2="62.11" xlink:href="#a"/>
      <linearGradient id="b" x1="577.09" x2="577.09" y1="1.99" y2="104.07" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#fff"/>
        <stop offset="1" stop-color="#fff" stop-opacity=".7"/>
      </linearGradient>
      <linearGradient id="f" x1="65.83" x2="65.83" y1="1.99" y2="104.07" xlink:href="#b"/>
      <linearGradient id="g" x1="142.7" x2="142.7" y1="1.99" y2="104.07" xlink:href="#b"/>
      <linearGradient id="h" x1="341.37" x2="341.37" y1="1.99" y2="104.07" xlink:href="#b"/>
      <linearGradient id="i" x1="424.64" x2="424.64" y1="1.99" y2="104.07" xlink:href="#b"/>
      <linearGradient id="j" x1="501.18" x2="501.18" y1="1.99" y2="104.07" xlink:href="#b"/>
      <linearGradient id="k" x1="279.29" x2="279.29" y1="1.99" y2="104.07" xlink:href="#b"/>
      <linearGradient id="l" x1="221.26" x2="221.26" y1="1.99" y2="104.07" xlink:href="#b"/>
    </defs>
    <path fill="url(#c)" d="M656.67 2.66l-39.63 67.72-6.07-10.62 33.14-57.1h12.56z"/>
    <path fill="url(#a)" d="M716.68 19.73l-.11 32.12-10.97 19.07L705.45 0l11.23 19.73z"/>
    <path fill="url(#d)" d="M666.06 36.63h-41.65l6.05 10.95h42.95l-7.35-10.95z"/>
    <path fill="url(#e)" d="M644.11 2.66l38.79 67.72 6.07-10.62-32.3-57.1h-12.56z"/>
    <path fill="url(#b)" d="M571.28 60.39l-25.64-32.73H557l19.95 25.5 19.82-25.5H607l-24.9 32.07 26.62 34h-11.33L576.46 67l-20.7 26.67h-10.3z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#f)" d="M37.66 27.69h24.28q11.79 0 18 2.89 8 3.78 8 13a12.81 12.81 0 01-2.84 7.78 11.78 11.78 0 01-5.87 4.48 18.25 18.25 0 0110.67 5.82A16.87 16.87 0 0194 73.12q0 14.35-15.61 18.79a56.32 56.32 0 01-15 1.77H37.66zm25.12 25.63q10.53 0 14.53-4.75a6.9 6.9 0 001.5-4.57 6.06 6.06 0 00-1.75-4.54 11.41 11.41 0 00-4.45-2.59q-4.11-1.3-12-1.3H46.28v17.8zm22.08 20.37q0-12.54-20.55-12.54h-18v24.7h18.28q14.13 0 18.55-6a10.24 10.24 0 001.72-6.16z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#g)" d="M171.57 48q0 14.67-13.51 20.27l12.53 25.4h-10l-11.32-23a63.88 63.88 0 01-9.65.7h-17.17v22.31h-8.62v-66h27.08q14.63 0 21.85 4 8.82 4.87 8.81 16.32zm-30.8 15.56q10.44 0 15.47-2.89 6.19-3.59 6.2-12.11 0-11.1-14.36-12.58a76.29 76.29 0 00-8.52-.47h-17.11v28.06z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#h)" d="M312.5 27.69h7.55l42.5 52.1v-52.1h7.69v66H363L320.19 41.2v52.48h-7.69z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#i)" d="M396.47 27.69h24.28q11.79 0 18 2.89 8 3.78 8 13a12.75 12.75 0 01-2.84 7.78 11.78 11.78 0 01-5.91 4.48 18.22 18.22 0 0110.67 5.82 16.87 16.87 0 014.1 11.46q0 14.35-15.61 18.79a56.32 56.32 0 01-15 1.77h-25.69zm25.12 25.63q10.53 0 14.54-4.75a7 7 0 001.49-4.57 6 6 0 00-1.75-4.54 11.33 11.33 0 00-4.45-2.59q-4.1-1.3-12-1.3h-14.33v17.8zm22.09 20.37q0-12.54-20.55-12.54h-18v24.7h18.27q14.13 0 18.55-6a10.24 10.24 0 001.73-6.16z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#j)" d="M475.62 85.31a32.36 32.36 0 01-7.69-10.51 29.08 29.08 0 01-2.81-12.3 39.62 39.62 0 011.28-10.74 32.72 32.72 0 013.65-8.39 33.48 33.48 0 015.69-6.92 34.54 34.54 0 017.36-5.22 39.08 39.08 0 0118.5-4.47 38.41 38.41 0 0117.9 4.29 33.16 33.16 0 0112.86 11.65 31.81 31.81 0 014.89 17.3q0 15.06-10.62 24.93a35.74 35.74 0 01-16.08 8.53 45.45 45.45 0 01-10.9 1.17A35.76 35.76 0 01487 92.16a35.29 35.29 0 01-11.38-6.85zM482 41.76a24.68 24.68 0 00-7.76 18.32 25.82 25.82 0 002.14 10.48 27.16 27.16 0 0025.22 16.31 26.22 26.22 0 0018.73-7.27 24.08 24.08 0 007.79-18.22 26.6 26.6 0 00-13.56-23.25 25.06 25.06 0 00-12.77-3.64 30.4 30.4 0 00-11.35 1.94 26.7 26.7 0 00-8.44 5.33z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#k)" d="M275 27.51h8.64v66.08H275z" transform="translate(-37.66 -25.33)"/>
    <path fill="url(#l)" d="M248 93.59h8.63l-31-66.08h-8.66l-31 66.08h8.63L205.14 71h32.24zm-39.3-30.14l12.56-26.76 12.56 26.76z" transform="translate(-37.66 -25.33)"/>
    <title>BrainBox AI</title>
  </svg>
</template>

<script>
  export default {
    name: 'BrainBoxLogo',
  }
</script>
