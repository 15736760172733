<template>
  <div class="breadcrumbs text-lg">
    <ul>
      <li>
        <router-link
          v-for="(item, index) in list"
          :key="index"
          :to="{ name: item.name, params: { slug: $route.params.slug } }"
          :class="
            ($route.path.includes('building') && index === 0) ||
            item.name == 'decarb-building' ||
            item.name == 'summary' ||
            item.name == 'energy' ||
            item.name == 'emissions'
              ? 'hidden'
              : ''
          ">
          {{ $t(item.name) }}
          <span v-if="index < list.length - 1" class="text-gray-500">| </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
export default {
  setup() {
    //data
    const route = useRoute();
    const state = reactive({
      list: [],
    });

    //onMounted
    onMounted(() => {
      let matched = route.matched; //get matched router infomation
      state.list = matched;
    });

    //watch
    watch(
      () => route.matched,
      (newVal, oldVal) => {
        let matched = newVal;
        state.list = matched; //update router list
      }
    );

    //return
    return {
      ...toRefs(state), //toRefs() make stats responsively
    };
  },
};
</script>
