<template>
  <svg width="0" height="0" class="hidden">
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 26.73" id="arrow-down-thin">
      <path fill="currentColor" d="M0 1.73L1.73 0 25 23.27 48.27 0 50 1.73l-25 25-25-25z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 28.27" id="arrow-down">
      <path fill="currentColor" d="M25 28.27l-25-25L3.27 0 25 21.73 46.73 0 50 3.27l-25 25z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.73 50" id="arrow-left-thin">
      <path fill="currentColor" d="M25 0l1.73 1.73L3.46 25l23.27 23.27L25 50 0 25 25 0z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.27 50" id="arrow-left">
      <path fill="currentColor" d="M0 25L25 0l3.27 3.27L6.54 25l21.73 21.73L25 50 0 25z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.73 50" id="arrow-right-thin">
      <path fill="currentColor" d="M1.73 50L0 48.27 23.27 25 0 1.73 1.73 0l25 25-25 25z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.27 50" id="arrow-right">
      <path fill="currentColor" d="M28.27 25l-25 25L0 46.73 21.73 25 0 3.27 3.27 0l25 25z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 26.73" id="arrow-up-thin">
      <path fill="currentColor" d="M50 25l-1.73 1.73L25 3.46 1.73 26.73 0 25 25 0l25 25z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 28.27" id="arrow-up">
      <path fill="currentColor" d="M25 0l25 25-3.27 3.27L25 6.54 3.27 28.27 0 25 25 0z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 42.88" id="buildings">
      <path fill="currentColor" d="M49 40.85h-1.73v-17a1 1 0 0 0-.62-.93l-12.28-5.23h-.77l-.18.1-.13.1L28 22.6a1 1 0 0 0-.34.76v17.49h-3.4V11.79L35 16.91a1 1 0 1 0 .87-1.84L23.68 9.26l-.21-.07a1 1 0 0 0-.35 0H23a1.19 1.19 0 0 0-.35.15l-.11.08-5.75 4.72a1 1 0 0 0-.38.79v25.92h-4V2.6L24.26 8a1 1 0 1 0 .85-1.85L11.9.1a1.18 1.18 0 0 0-.38-.1h-.11a1 1 0 0 0-.65.27L4.17 6.32a1 1 0 0 0-.33.75v33.78H1a1 1 0 1 0 0 2h48a1 1 0 1 0 0-2zm-38.54 0H5.87V7.52l4.57-4.19zm11.77 0h-3.76V15.39l3.74-3.07zm10.72 0h-3.27v-17l3.25-2.91zm12.31 0H35V20.16l10.27 4.37z"></path>
      <path fill="currentColor" d="M37 28.22l5.94 1.24h.21a1 1 0 0 0 .2-2l-5.93-1.23a1 1 0 0 0-1.21.79 1 1 0 0 0 .79 1.2zm.09 4.33l5.93.72h.13a1 1 0 0 0 .12-2l-5.94-.71a1 1 0 0 0-.24 2zm6.13 2.62l-5.94-.42a1 1 0 1 0-.14 2l5.93.41h.08a1 1 0 0 0 .07-2z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 35.57" id="clients">
      <path fill="currentColor" d="M38.84 17.8c-.64-.23-.88-.47-.91-.57s0-.16.16-.53l.14-.14C40 14.74 41.65 9.8 40.15 6.22 39.16 3.87 37 2.58 34 2.58s-5.12 1.29-6.11 3.64c-1.51 3.57.12 8.51 1.91 10.34l.15.14c.21.37.16.52.16.53s-.29.35-1 .6a17.94 17.94 0 0 0-2.74.77 22 22 0 0 0-2.16-.54c-1-.37-1.22-.74-1.25-.85s-.06-.38.22-.87l.2-.19c2.07-2.11 4-7.84 2.21-12C24.52 1.48 22 0 18.64 0s-5.88 1.48-7 4.16c-1.75 4.15.14 9.87 2.21 12l.19.19a1.19 1.19 0 0 1 .23.87c0 .11-.24.5-1.33.88C4.47 19.78 0 25.34 0 34.18a1.38 1.38 0 0 0 1.38 1.39h34.56a1.39 1.39 0 0 0 1.39-1.39v-1.19h11.29A1.38 1.38 0 0 0 50 31.6c0-7.6-3.86-12.37-11.16-13.8zm-36 15c.43-6.69 4.07-10.73 10.82-12a1.12 1.12 0 0 0 .35-.12 4.41 4.41 0 0 0 2.9-2.54 3.85 3.85 0 0 0-.61-3.42 1.64 1.64 0 0 0-.23-.28l-.12-.1-.1-.09c-1.5-1.54-2.83-6.17-1.64-9 .7-1.66 2.16-2.47 4.46-2.47s3.76.81 4.46 2.47c1.19 2.82-.15 7.45-1.63 9l-.15.13-.08.07a1.32 1.32 0 0 0-.24.29 3.85 3.85 0 0 0-.6 3.42 4.52 4.52 0 0 0 3.05 2.59l.2.05c6.78 1.29 10.43 5.33 10.86 12zM37 30.22a13.87 13.87 0 0 0-6.58-9.9 3.77 3.77 0 0 0 2.36-2.19 3.45 3.45 0 0 0-.53-3.08 2 2 0 0 0-.23-.27l-.18-.16c-1.09-1.11-2.37-4.88-1.34-7.33C31 6 32.19 5.35 34 5.35s3 .65 3.59 1.94c1 2.45-.25 6.22-1.33 7.32a.52.52 0 0 1-.11.1l-.07.07a1.16 1.16 0 0 0-.25.29 3.48 3.48 0 0 0-.52 3.07A4 4 0 0 0 38 20.45l.19.05c5.61 1.07 8.56 4.26 9 9.72z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="close">
      <path fill="currentColor" d="M50 3.27L46.73 0 25 21.73 3.27 0 0 3.27 21.73 25 0 46.73 3.27 50 25 28.27 46.73 50 50 46.73 28.27 25 50 3.27z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 46.06" id="home">
      <path fill="currentColor" d="M44.7 23.64A1.36 1.36 0 0 0 43.34 25v18.34H32.27V25a1.36 1.36 0 0 0-1.36-1.36H19.09A1.36 1.36 0 0 0 17.73 25v18.34H6.66V25a1.36 1.36 0 0 0-2.72 0v19.7a1.36 1.36 0 0 0 1.36 1.36h39.4a1.36 1.36 0 0 0 1.36-1.36V25a1.36 1.36 0 0 0-1.36-1.36zm-15.15 2.72v17h-9.1v-17zM49.51 20L25.87.32a1.33 1.33 0 0 0-1.74 0L.49 20a1.36 1.36 0 0 0 1.74 2.1L25 3.14l22.77 19a1.36 1.36 0 0 0 .87.31 1.36 1.36 0 0 0 .87-2.45z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 32.9" id="menu">
      <path fill="currentColor" d="M0 0h50v3.49H0zm0 14.7h50v3.49H0zm0 14.71h50v3.5H0z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.68 52.39" id="pin">
      <path fill="currentColor" d="M20.84 0A20.87 20.87 0 0 0 0 20.84c0 11.25 19.32 30.08 20.15 30.88l.69.67.7-.67c.82-.8 20.14-19.63 20.14-30.88A20.87 20.87 0 0 0 20.84 0zm0 49.59C17.36 46.09 2 30.12 2 20.84a18.84 18.84 0 0 1 37.68 0c0 9.28-15.36 25.25-18.84 28.75z"></path>
      <path fill="currentColor" d="M20.8 9.14A11.66 11.66 0 1 0 32.46 20.8 11.67 11.67 0 0 0 20.8 9.14zm0 21.32a9.66 9.66 0 1 1 9.66-9.66 9.67 9.67 0 0 1-9.66 9.66z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.68 52.52" id="pin-filled">
      <path fill="currentColor" d="M20.84 0A20.84 20.84 0 000 20.84c0 11.51 20.84 31.68 20.84 31.68s20.84-20.17 20.84-31.68A20.84 20.84 0 0020.84 0m-.05 32A11.2 11.2 0 1132 20.79 11.19 11.19 0 0120.79 32" data-name="Layer 2"/>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 41.32" id="portfolio">
      <path fill="currentColor" d="M48 7.66H34.72C34.72 2.79 31.18 0 25 0s-9.72 2.79-9.72 7.66H2A2.09 2.09 0 0 0 0 9.8v29.54a2 2 0 0 0 2 2h46a2 2 0 0 0 2-2V9.8a2.09 2.09 0 0 0-2-2.14zM25 2.35c3.36 0 7.37.92 7.37 5.31H17.63c0-4.39 4.01-5.31 7.37-5.31zm22.65 21.37V39H2.35V23.91a53.86 53.86 0 0 0 5.37 1.85 53.15 53.15 0 0 0 16.79 2.71 56 56 0 0 0 17.58-2.87c2.1-.6 3.96-1.25 5.56-1.88zm-23.14 2a58.07 58.07 0 0 1-16.24-2.25c-1-.34-1.89-.68-2.69-1-1.12-.47-2.2-.96-3.23-1.47V10h45.3v10.84a53.9 53.9 0 0 1-6.39 2.57 61.11 61.11 0 0 1-16.75 2.34z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" id="profile">
      <path fill="currentColor" d="M26 0a26 26 0 1 0 26 26A26 26 0 0 0 26 0zm0 50a24 24 0 1 1 24-24 24 24 0 0 1-24 24z"></path>
      <path fill="currentColor" d="M26 25.81a6.34 6.34 0 1 0-6.33-6.33A6.33 6.33 0 0 0 26 25.81zM28.66 27h-5.32a8 8 0 0 0-8 8v1.27a1.31 1.31 0 0 0 1.31 1.31h18.69a1.31 1.31 0 0 0 1.31-1.31V35a8 8 0 0 0-7.99-8z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.71 49.31" id="grid">
      <path fill="currentColor" d="M.1 39.2h10.11v10.11H.1zM20 39.2h10.11v10.11H20zM39.6 39.2h10.11v10.11H39.6zM0 19.8h10.11v10.11H0zM19.91 19.8h10.11v10.11H19.91zM39.51 19.8h10.11v10.11H39.51zM.1 0h10.11v10.11H.1zM20 0h10.11v10.11H20zM39.6 0h10.11v10.11H39.6z"/>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 53" id="search">
      <path fill="currentColor" d="M53 50.44L40.23 37.67A22.89 22.89 0 0039 6.68a22.86 22.86 0 10-1.34 33.55L50.44 53zm-43.76-14a19.24 19.24 0 1127.21 0 19.28 19.28 0 01-27.21.01z" data-name="Layer 26"/>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 30" id="filter">
      <path fill="currentColor" d="M14 30l8-5v-8L35.999 0H0l14 17z"/>
    </symbol>

    <!-- Temporary Icons -->
    <!-- <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="portfolios">
      <path fill="currentColor" d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="menu">
      <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" id="close">
      <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="arrow-down">
      <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" id="clients">
      <path fill="currentColor" d="M384 385.306v-26.39c35.249-19.864 64-69.386 64-118.916 0-79.529 0-144-96-144s-96 64.471-96 144c0 49.53 28.751 99.052 64 118.916v26.39C211.449 394.18 128 447.516 128 512h448c0-64.484-83.449-117.82-192-126.694z"></path>
      <path fill="currentColor" d="M163.598 397.664c27.655-18.075 62.04-31.818 99.894-40.207-7.527-8.892-14.354-18.811-20.246-29.51C228.039 300.33 220 269.918 220 240c0-43.021 0-83.655 15.3-116.881 14.853-32.252 41.564-52.248 79.611-59.744C306.454 25.135 283.941 0 224 0c-96 0-96 64.471-96 144 0 49.53 28.751 99.052 64 118.916v26.39C83.449 298.18 0 351.516 0 416h139.503c7.259-6.455 15.298-12.586 24.095-18.336z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="buildings">
      <path fill="currentColor" d="M0 512h256V0H0v512zM160 64h64v64h-64V64zm0 128h64v64h-64v-64zm0 128h64v64h-64v-64zM32 64h64v64H32V64zm0 128h64v64H32v-64zm0 128h64v64H32v-64zm256-160h224v32H288zm0 352h64V384h96v128h64V224H288z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="home">
      <path fill="currentColor" d="M512 304l-96-96V64h-64v80l-96-96L0 304v16h64v160h160v-96h64v96h160V320h64z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="profile">
      <path fill="currentColor" d="M288 353.306v-26.39c35.249-19.864 64-69.386 64-118.916 0-79.529 0-144-96-144s-96 64.471-96 144c0 49.53 28.751 99.052 64 118.916v26.39C115.449 362.18 32 415.516 32 480h448c0-64.484-83.449-117.82-192-126.694z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="location">
      <path fill="currentColor" d="M256 0C167.634 0 96 71.634 96 160c0 160 160 352 160 352s160-192 160-352C416 71.634 344.365 0 256 0zm0 256c-53.02 0-96-42.98-96-96s42.98-96 96-96 96 42.98 96 96-42.98 96-96 96z"></path>
    </symbol> -->

    <!-- Socials -->
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" id="youtube">
      <path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="twitter">
      <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="instagram">
      <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" id="facebook">
      <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" id="linkedin">
      <path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path>
    </symbol>
  </svg>
</template>

<script>
export default {
  name: 'Icons'
}
</script>
