<template>
  <transition name="popup-fade">
    <div class="popup-backdrop">
      <div
        class="popup"
        role="dialog"
        aria-labelledby="popupTitle"
        aria-describedby="popupDescription">
        <header
          class="popup-header flex flex-col items-center bg-red-500 text-white"
          id="popupTitle">
          <h1 class="font-bold uppercase">
            CONDITIONS D’UTILISATION DE L’UTILISATEUR FINAL
          </h1>
        </header>
        <section
          class="flex flex-col popup-body max-w-4xl"
          id="popupDescription">
          <p class="font-bold my-6">Version du 4 Juin 2024</p>
          <div class="contentSction font-base mb-4">
            <p>
              IMPORTANT : LE PROPRIÉTAIRE DU TABLEAU DE BORD BRAINBOX AI VOUS PERMET
              D’UTILISER LE TABLEAU DE BORD BRAINBOX AI UNIQUEMENT SI VOUS ACCEPTEZ
              TOUTES LES CONDITIONS GÉNÉRALES CONTENUES DANS LES PRÉSENTES
              CONDITIONS D’UTILISATION DE L’UTILISATEUR FINAL(«
              <strong>Conditions</strong> »).
            </p>
          </div>
          <ol class="eutu space-y-4 list-decimal list-inside" type="1">
      <li>
              LES SERVICES BRAINBOX AI ET LE CONTRAT BRAINBOX AI SERVICES
        <p>
          Brainbox AI Inc., ayant une place d’affaires au 2001, boul. Robert Bourassa, 
          bureau 840, Montréal, Québec, H3A 2A6 Canada ou une de ses sociétés
          affiliées (« <strong>Brainbox AI</strong> »), a accepté de fournir à
          votre organisation un service infonuagique utilisant une technologie
          autonome basée sur l’intelligence artificielle pour l’optimisation de
          la consommation d’énergie des systèmes de chauffage, de ventilation et
          de climatisation (« CVC ») (les «
          <strong>Services Brainbox AI</strong> »).
        </p>
        <p>
          Brainbox AI fourni les Services Brainbox AI directement et, dans certains cas, elle a accordé à
          divers revendeurs ou distributeurs le droit de fournir les Services Brainbox AI (les «
          <strong>Revendeurs</strong> »).
        </p>
        <p>
          Ce tableau de bord est offert en ligne via un navigateur Web, afin de vous permettre de visualiser
          les informations relatives au rendement des Services Brainbox AI ou d’avoir accès à d’autres
          fonctionnalités qui composent les Services Brainbox AI (le « <strong>Tableau de bord Brainbox AI </strong>»).
          Brainbox AI se réserve le droit de:(i) modifier l’une ou l’ensemble des caractéristiques et
          fonctionnalités du Tableau de bord Brainbox AI ; ou (ii) remplacer tout ou partie de ces
          caractéristiques et fonctionnalités par leur équivalent substantiel; ou (iii) modifier l’infrastructure,
          le matériel et les logiciels sous-jacents existants utilisés pour fournir le Tableau de bord Brainbox
          AI lorsque l’expansion et la nouvelle technologie sont jugées nécessaires ; ou (iv) interrompre de
          façon générale l’accès au Tableau de bord Brainbox AI.
        </p>
        <p>
          Si vous avez obtenu l’accès à ce Tableau de bord Brainbox AI, votre organisation et Brainbox AI,
          ou l’un de ses Revendeurs, ont signé une entente pour l’achat par votre organisation des Services
          Brainbox AI (le « <strong>Contrat</strong> »). Le Contrat comprend un bon de commande et de ses annexes, ainsi
          que les Conditions d’utilisation publiées sur le site Web de Brainbox AI à l’adresse
          <a target="_blank" href="https://brainboxai.com/fr/termes-d-utilisation"
            >https://brainboxai.com/fr/termes-d-utilisation</a
          >
          ou sur le site Web du Revendeur, selon le cas. Tous
          les termes majuscules qui ne sont pas autrement définis dans les présentes Conditions ont le
          sens donné dans le Contrat.

        </p>
      </li>
      <li>
        DROIT D’UTILISATION
        <p>
          Les présentes Conditions vous accordent le droit d’utiliser le Tableau de bord Brainbox AI, mais
          uniquement conformément aux termes et conditions des présentes Conditions et du Contrat.
        </p>
        <p>
          Votre droit d’utiliser le Tableau de bord Brainbox AI est conditionné par les éléments suivants :
          (a) la validité du Contrat ; et (b) la non-suspension des Services Brainbox AI par Brainbox AI ou
          un Revendeur en vertu des termes du Contrat ; et (c) vous avez été désigné à titre d’utilisateur
          autorisé par votre organisation (un « <strong>Utilisateur Autorisé</strong> ») ; et (d) votre utilisation du Tableau
          de bord Brainbox AI conformément aux présentes Conditions.
        </p>
        <p>
          Vous êtes seul responsable de votre utilisation du Tableau de bord Brainbox AI et de votre usage
          des informations qui y sont contenus.
        </p>
        <p>
          Brainbox AI et ses Revendeurs se réservent le droit de surveiller votre utilisation du Tableau de
          bord Brainbox AI afin de : (a) s’assurer que vous ne violez pas les présentes Conditions, ou les
          lois et réglementations applicables ; et (b) collecter des données transactionnelles et de
          performance liée à l’utilisation générale du Tableau de bord Brainbox AI à des fins d’optimisation.
          Ces données collectées sont anonymisées et ne seront pas identifiables personnellement.
        </p>
        <p>
          Vous acceptez d’indemniser, de défendre et de dégager de toute responsabilité Brainbox AI et
          ses Revendeurs, leurs dirigeants, administrateurs, employés, agents et concédants de licence
          contre toute réclamation, responsabilité, dommage, perte, coût et dépense (y compris les
          honoraires d’avocat raisonnables) découlant de ou en relation avec votre utilisation du Tableau
          de bord Brainbox AI d’une manière non autorisée par le Contrat.
        </p>
      </li>
      <li>
        CONFIGURATION REQUISE
        <p>
          Vous êtes responsable de vous assurer que les systèmes informatiques sur lesquels le Tableau
          de bord Brainbox AI doit être consulté ainsi que le système de télécommunication utilisé pour
          établir la connexion répondent aux exigences minimales prescrites par Brainbox AI.
        </p>
      </li>
      <li>
        CONNEXION
        <p>
          En tant qu’Utilisateur Autorisé du Tableau de bord Brainbox AI, vous devrez créer un compte.
        </p>
        <p>
          On vous demandera de fournir certaines informations de contact professionnel afin que
          Brainbox AI ou ses Revendeurs puissent vous envoyer les informations d’accès à la connexion.
          Vous recevrez alors un identifiant de connexion vous donnant accès au Tableau de bord Brainbox
          AI. Dans le cadre du processus de configuration, vous devrez d’abord créer un mot de passe,
          puis configurer l’authentification multi-facteur.
        </p>
        <p>
          Vous êtes seul responsable de fournir des coordonnées professionnelles exactes et de garder
          les informations de connexion à votre compte confidentielles et sécurisées.
        </p>
        <p>
          Une fois votre connexion configurée, vous êtes seul responsable de toute activité qui se produit
          sous le Compte Client.
        </p>
      </li>
      <li>
        RESTRICTIONS
        <p>Vous vous engager à ne pas:</p>
        <ol class="restrictionList list-inside" type="a">
          <li>
            transférer, vendre, louer, distribuer, concéder sous licence ou sous-licence le Tableau de
            bord Brainbox AI, en tout ou en partie, sauf dans les cas expressément prévus dans le
            Contrat; ou
          </li>
          <li>
            faire des travaux dérivés, apporter des modifications, des corrections, des altérations, des
            améliorations ou d’autres ajouts au Tableau de bord Brainbox Ai; ou
          </li>
          <li>
            décompiler, désassembler, faire de le rétro-compilation ou de l’ingénierie inverse ou tenter
            de toute autre manière de dériver le code source de tout ou partie du Tableau de bord
            Brainbox AI; ou
          </li>
          <li>
            utiliser le Tableau de bord Brainbox AI comme composant ou base de produits ou de
            services préparés pour la vente commerciale, la sous-licence, la location, l’accès ou la
            distribution ; ou
          </li>
          <li>
            accéder au Tableau de Bord Brainbox AI afin de créer un produit ou service similaire ou
            compétitif ; ou
          </li>
          <li>
            obscurcir, supprimer ou modifier les logos, marques de commerce, liens Internet, avis de
            brevet ou de droit d’auteur, légendes de confidentialité ou de propriété ou autres avis ou
            marquages qui se trouvent sur ou font partie du Tableau de bord Brainbox AI ; ou
          </li>
          <li>
            mettre le Tableau de bord Brainbox AI à la disposition d’une personne qui n’est pas
            autorisé par votre organisation ou d’un tiers par le biais de services en ligne, de connexion
            à distance ou de liens réseau ou de télécommunication de quelque nature que ce soit ; ou
          </li>
          <li>
            utiliser le Tableau de bord Brainbox AI à des fins illégales ou l’utiliser pour se livrer à une
            activité illégale, frauduleuse ou non autorisée ; ou
          </li>
          <li>
            utiliser le Tableau de bord Brainbox AI en présence d’un tiers qui, de l’avis de Brainbox
            AI, est un concurrent de Brainbox AI ; ou
          </li>
          <li>
            fournir, révéler, divulguer ou mettre à disposition le Tableau de bord Brainbox AI, ou en
            permettre l’utilisation par des personnes autres que vous-même.
          </li>
        </ol>
        <p>
          Les présents Conditions ne vous accordent, ni à vous ni à quiconque, le droit d’accéder au code
          source du Tableau de bord Brainbox AI ou de l’obtenir.
        </p>
      </li>
      <li>
        CHANGEMENTS APPLIQUÉS AU TABLEAU DE BORD BRAINBOX AI
        <p>
          Les Services Brainbox AI dépendent du fonctionnement continu et stable des systèmes CVC et
          de la GTB de l'Emplacement. Vous reconnaissez qu'en apportant des modifications à certains
          éléments contenus dans le Tableau de bord Brainbox AI, que ces modifications soient autorisées
          par Brainbox AI ou non, telles que la modification des programmes ou des normes de consigne,
          vous pourriez entraver la capacité de Brainbox AI à optimiser les systèmes CVC de
          l'Emplacement par le biais des Services Brainbox AI.
        </p>
      </li>
      <li>
        PROPRIÉTÉ ET LIMITES D’UTILISATION
        <p>
          Le Tableau de bord Brainbox AI, y compris, mais sans s’y limiter, les logiciels, les graphiques, les
          textes, les images et autres contenus, sont des droits de propriété intellectuelle détenus par
          Brainbox AI ou de ses concédants de licence et sont protégés par les lois et règlements relatives
          à la propriété intellectuelle.
        </p>
        <p>
          Vous reconnaissez et acceptez que toutes les marques de commerce, inventions (brevetables
          ou non), demandes de brevet, brevets, œuvres protégées par des droits d’auteur, secrets
          commerciaux, savoir-faire ou autres droits de propriété intellectuelle dans ou liés au Tableau de
          bord Brainbox AI et a tout logiciel, graphique, texte, image et autre contenu sont la propriété
          exclusive de Brainbox AI ou de ses concédants de licence, et sont contrôlés par Brainbox AI.
        </p>
        <p>
          Vous ne devez prendre aucune action qui remette en cause les droits de propriété intellectuelle
          de Brainbox AI, y compris, mais sans s’y limiter, les actions en nullité intentées contre les brevets
          détenus par Brainbox AI.
        </p>
        <p>
          Tous les droits, titres et intérêts qui ne sont pas expressément énoncés dans les présentes
          Conditions sont expressément réservés par Brainbox AI.
        </p>
      </li>
      <li>
        CONFIDENTIALITÉ
        <p>
          Vous reconnaissez que toutes les informations relatives au Tableau de bord Brainbox AI ou que
          vous avez obtenues ou obtiendrez suite à l’utilisation du Tableau de bord Brainbox AI sont de nature confidentielle et sont couverts par des droits d’auteur appartenant exclusivement à
          Brainbox AI. Vous vous engagez à mettre en œuvre des mesures de sécurité raisonnables pour
          protéger ces informations et de les garder confidentielles sans les divulguer à des tiers, sauf
          disposition contraire du Contrat.
        </p>
        <p>
          Le Tableau de bord Brainbox AI et les Services Brainbox AI ne sont pas destinés à accepter des
          informations personnelles autre que le nom et les coordonnées professionnelles de base
          (courriel), mais si vous avez des questions concernant la manière dont Brainbox AI traite les
          données permettant d’identifier tout personne physique ou d’autres questions de protection de la
          vie privée, veuillez-vous référer à la politique de confidentialité de Brainbox AI, qui se trouve à
          l’adresse
          <a target="_blank" href="https://brainboxai.com/fr/confidentialite"
            >https://brainboxai.com/fr/confidentialite</a
          >
        </p>
      </li>
      <li>
        CLAUSE DE NON-RESPONSABILITE CONCERNANT L’EXACTITUDE ET L’EXHAUSTIVITÉ DES INFORMATIONS
        <p>
          Sauf disposition contraire dans le Contrat, aucune garantie n’est fournie quant à l’exactitude et à
          l’exhaustivité des informations contenues dans le Tableau de bord Brainbox AI. Vous
          reconnaissez que les Services Brainbox AI sont destinés à vous aider à améliorer l’efficacité
          énergétique ou à surveiller d’autres indicateurs liés à l’énergie, mais que Brainbox AI et ses
          Revendeurs ne peuvent garantir des résultats ou conséquences spécifiques, et que vos
          économies d’énergie réelles peuvent varier en fonction de votre situation spécifique.
        </p>
        <p>
          L’exactitude des informations contenues dans le Tableau de bord Brainbox AI dépend de
          l’exactitude et de l’exhaustivité des Données du Client (ce terme est défini dans le Contrat, plus
          précisément dans les Termes d’utilisation) fournies par vous ou votre organisation conformément
          au Contrat, ou de tout donnée que vous avez introduite dans le Table de bord Brainbox AI.
        </p>
        <p>
          La mesure et la vérification des économies d’énergie dans les Emplacements affichés sur le
          Tableau de bord Brainbox AI sont basées sur la méthodologie normalisée développée par
          l’American Society of Heating, Refrigerating and Air-Conditioning Engineers (ASHRAE) et le
          protocole international de mesure et de vérification de la performance (IPMVP®). L’estimation
          des économies d’énergie et d’émissions par Emplacement est fondée sur un certain nombre
          d’hypothèses et de variables qui peuvent différer des conditions réelles.
        </p>
        <p>
          Les émissions de portée calculées par la fourniture des services d'abonnement à la
          décarbonisation autonome, le cas échéant, sont basées sur les lignes directrices de l'EPA des
          États-Unis en matière de GES et sur les facteurs d'émissions de portée 1 et de portée 2 reconnus
          par l'industrie.
        </p>
      </li>
      <li>
        EXCLUSION DE GARANTIE
        <p>
          LE TABLEAU DE BORD BRAINBOX AI EST FOURNI « TEL QUEL ». BRAINBOX AI ET SES
          REVENDEURS NE GARANTISSENT PAS QUE LE TABLEAU DE BORD BRAINBOX AI OU LES
          INFORMATIONS QU’IL CONTIENT SONT EXEMPTS D’ERREURS OU QUE VOUS POURREZ
          UTILISER LE TABLEAU DE BORD BRAINBOX AI SANS PROBLÈMES NI INTERRUPTIONS,
          OU QU’IL SERA COMPATIBLE OU FONCTIONNERA AVEC TOUT LOGICIEL, SYSTÈME OU
          AUTRE SERVICE, OU QU 'IL SERA SÉCURISÉ, PRÉCIS, COMPLET OU EXEMPT DE CODE
          NUISIBLE. BRAINBOX AI ET SES REVENDEURS DÉCLINENT EXPRESSÉMENT TOUTE
          GARANTIE, REPRÉSENTATION ET CONDITION, EXPRESSE OU IMPLICITE, Y COMPRIS,
          MAIS SANS S’Y LIMITER, LES GARANTIES D’ABSENCE DE CONTREFAÇON, DE QUALITÉ
          MARCHANDE, D’ADÉQUATION À UN USAGE PARTICULIER, DE QUALITÉ SATISFAISANTE
          ET/OU DÉCOULANT D’UNE TRANSACTION, D’UN USAGE OU D’UNE PRATIQUE COMMERCIALE EN CE QUI CONCERNE LE TABLEAU DE BORD BRAINBOX AI OU LES
          INFORMATIONS CONTENUES SUR LE TABLEAU DE BORD BRAINBOX AI.
        </p>
        <p>
          COMME CERTAINES JURIDICTIONS NE PERMETTENT PAS L’EXCLUSION OU LA
          LIMITATION DES GARANTIES IMPLICITES, LA LIMITATION CI-DESSUS PEUT NE PAS
          S’APPLIQUER À VOUS. DANS LA MESURE OÙ UNE GARANTIE IMPLICITE NE PEUT ÊTRE
          EXCLUE, CETTE GARANTIE EST LIMITÉE À LA GARANTIE LA PLUS STRICTE APPLICABLE
          DANS CETTE JURIDICTION.
        </p>
      </li>
      <li>
        LIMITATION DE RESPONSABILITÉ
        <p>
          EN AUCUN CAS BRAINBOX AI ET SES REVENDEURS, AINSI QUE LEURS EMPLOYÉS,
          ADMINISTRATEURS, DIRIGEANTS ET AGENTS NE POURRONT ÊTRE TENUS
          COLLECTIVEMENT RESPONSABLES ENVERS VOUS POUR DES DOMMAGES DE
          QUELQUE NATURE QUE CE SOIT. TOUTE INDEMNITÉ DÉCOULANT DE OU EN RELATION
          AVEC VOTRE UTILISATION DU TABLEAU DE BORD BRAINBOX AI OU DE VOTRE
          UTILISATION DE TOUT INFORMATION CONTENU DANS LE TABLEAU DE BORD BRAINBOX
          AI, RÉCLAMÉE EN VERTU DE TOUT CONTRAT, DE L’ÉQUITÉ, D’UN DÉLIT CIVIL (Y
          COMPRIS LA NÉGLIGENCE OU LA RESPONSABILITÉ STRICTE), D’UNE GARANTIE OU
          AUTRE, SERA LIMITÉE À 100 $ AU TOTAL OU AU REMPLACEMENT OU À LA RÉPARATION
          DU TABLEAU DE BORD BRAINBOX AI (À LA SEULE DISCRÉTION DE BRAINBOX AI).
        </p>
        <p>
          EN AUCUN CAS BRAINBOX AI ET SES REVENDEURS, AINSI QUE LEURS EMPLOYÉS,
          ADMINISTRATEURS, DIRIGEANTS ET AGENTS NE POURRONT ÊTRE TENUS
          RESPONSABLES ENVERS VOUS DE TOUT DOMMAGE SPÉCIAL, ACCESSOIRE, INDIRECT
          OU CONSÉCUTIF DE QUELQUE NATURE QUE CE SOIT, OU DE LA PERTE DE PROFIT
          DÉCOULANT DE OU EN RELATION AVEC L’UTILISATION DU TABLEAU DE BORD
          BRAINBOX AI OU VOTRE UTILISATION DE TOUTE INFORMATION CONTENUE SUR LE
          TABLEAU DE BORD BRAINBOX AI, MÊME SI BRAINBOX AI OU SES REVENDEURS SONT
          INFORMÉS DE LA POSSIBILITÉ DE TELS PERTES OU DOMMAGES POTENTIELS.
        </p>
      </li>
      <li>
        RÉSILIATION
        <p>
          Vous comprenez et acceptez que Brainbox AI ou ses Revendeurs puissent résilier votre accès
          au Tableau de bord Brainbox AI, annuler l’
        </p>
        <p>
          accès à votre compte, et que tous vos droits en vertu des présentes Conditions seront
          automatiquement résiliés si : (a) votre organisation ne détient plus le droit de vous permettre
          d’utiliser le Tableau de bord Brainbox AI; ou (b) vous enfreignez les termes des présentes
          Conditions, ou les lois et réglementations applicables; ou (c) vous n’êtes plus un Utilisateur
          Autorisé désigné par votre organisation.
        </p>
        <p>
          En cas de résiliation de vos privilèges d’accès, vous acceptez de cesser d’utiliser le Tableau de
          bord Brainbox AI et de détruire immédiatement toutes les copies que vous avez pu faire.
        </p>
      </li>
      <li>
        LOI APPLICABLE ET JURIDICTION
        <p>
          Les présentes Conditions sont régies et interprétées conformément aux lois en vigueur dans la
          province de Québec, Canada, sans donner effet aux principes de conflits de lois. Les tribunaux
          ayant compétence dans et pour le district de Montréal dans la province de Québec auront
          compétence exclusive à l’égard de tout différend lié à le présent Contrat.
        </p>
      </li>
      <li>
        AMENDEMENTS
        <p>
          Brainbox AI et ses Revendeurs peuvent modifier ces Conditions ou toute section de celles-ci, en
          tout ou en partie, à tout moment.
        </p>
        <p>
          En cas de modification, vous serez invité à accepter les Conditions modifiées lors de votre
          prochain accès au Tableau de bord Brainbox AI.
        </p>
      </li>
      <li>
        ACCEPTATION
        <p>
          EN CLIQUANT SUR LE BOUTON « <strong>J’ACCEPTE</strong> » CI-DESSOUS PENDANT LE PROCESSUS
          DE CONNEXION POUR ACCÉDER AU TABLEAU DE BORD BRAINBOX AI, OU EN <strong>UTILISANT</strong>
          SIMPLEMENT LE TABLEAU DE BORD BRAINBOX AI, VOUS RECONNAISSEZ AVOIR LU LES
          PRÉSENTES CONDITIONS D’UTILISATION DE L’UTILISATEUR FINAL ET VOUS ACCEPTEZ
          D’Y ÊTRE LIÉ, PLUS PARTICULIÈREMENT PAR LA LIMITATION DE RESPONSABILITÉ
          PRÉVUE AU PARAGRAPHE 11.
        </p>
        <p>
          SI VOUS N’ÊTES PAS D’ACCORD AVEC LES PRÉSENTES CONDITIONS D’UTILISATION,
          VOUS NE DEVEZ PAS ACCÉDER AU TABLEAU DE BORD BRAINBOX AI NI L’UTILISER.
        </p>
      </li>
    </ol>
        </section>

        <header
          class="popup-header justify-center bg-red-500 text-white"
          id="popupTitle">
          <h1 class="font-bold uppercase">End-User Terms of Use</h1>
        </header>

        <section
          class="flex flex-col popup-body max-w-4xl"
          id="popupDescription">
          <p class="font-bold my-6">Version of June 4, 2024</p>
          <div class="contentSction font-base mb-4">
            <p class="uppercase">
              <span>
                IMPORTANT: THE OWNER OF THE BRAINBOX AI DASHBOARD ALLOWS YOU TO USE THE
                BRAINBOX AI DASHBOARD ONLY IF YOU ACCEPT ALL OF THE TERMS AND CONDITIONS
                CONTAINED IN THESE END-USER TERMS OF USE 
                (“<strong>EUTU</strong>”).
              </span>
            </p>
          </div>
          <ol class="eutu space-y-4 list-decimal list-inside" type="1">
      <li>
        BRAINBOX AI SERVICES AND BRAINBOX AI SERVICES AGREEMENT
        <p>
          Brainbox AI Inc., having a place of business at 2001 Robert Bourassa Blvd, suite 840, Montreal,
          Quebec, H3A 2A6 Canada, or any of its affiliated companies (“<strong>Brainbox AI</strong>”), has agreed to
          provide to your organization a cloud-based service using an autonomous artificial intelligence-
          based technology for the optimization of the energy consumption of your organization’s Heating,
          Ventilation and Air-conditioning (“HVAC”) systems  (the “<strong>Brainbox AI Services</strong
          >”).
        </p>
        <p>
          Brainbox AI provides the Brainbox AI Services directly, and in some cases, it has granted various
          resellers or distributors the right to provide the Brainbox AI Services (the “<strong>Resellers</strong>”).
        </p>
        <p>
          This online dashboard is offered via an internet browser, to allow you to view the performance-
          related information of the Brainbox AI Services or have access to other functionalities which
          comprise the Brainbox AI Services (the “<strong>Brainbox AI Dashboard</strong>”). Brainbox AI reserves the
          right to: (i) modify any or all features and functionalities of the Brainbox AI Dashboard; or (ii)
          substitute any or all such features and functionalities with their substantial equivalent; or (iii)
          change existing infrastructure, hardware and underlying software used to provide the Brainbox AI
          Dashboard as expansion and new technology is deemed necessary; or (iv) discontinue generally
          the access to its Brainbox AI Dashboard.
        </p>
        <p>
          If you have been granted access to this Brainbox AI Dashboard, your organization and
          Brainbox AI, or one of its Resellers, have signed an agreement for the purchase by your
          organization of the Brainbox AI Services (the <strong>“Agreement”</strong>). The Agreement is comprised of an
          Order Form and its schedules, as well as Terms of Service posted on the Brainbox AI website at
          <a target="_blank" href="https://brainboxai.com/en/terms-of-service"
            >https://brainboxai.com/en/terms-of-service</a>
            or the Reseller’s website, as applicable. All capitalized
            terms not otherwise defined in these EUTU shall have the meaning given in the Agreement.
        </p>
      </li>
      <li>
        RIGHT TO USE
        <p>
          These EUTU grant you the right to use the Brainbox AI Dashboard, but only in compliance with
          these EUTU and the terms and conditions of the Agreement.
        </p>
        <p>
          Your right to use the Brainbox AI Dashboard is conditional upon: (a) the Agreement being valid
          and in full force and effect; and (b) the Brainbox AI Services not having been suspended by
          Brainbox AI or a Reseller under the terms of the Agreement; and (c) you have been designated
          as an authorized user by your organization (an <strong>“Authorized User”</strong>); and (d) your use of the
          Brainbox AI Dashboard in compliance with these EUTU.
        </p>
        <p>
          You are solely responsible for your use of the Brainbox AI Dashboard and your use of the
          information contained therein.
        </p>
        <p>
          Brainbox AI and its Resellers reserve the right to monitor your use of the Brainbox AI Dashboard
          in order to: (a) ensure that you are not violating these EUTU, or applicable laws and regulations;
          and (b) collect transactional and performance data related to the general use of the Brainbox AI
          Dashboard for optimization purposes. This data is anonymized and will not be personally
          identifiable.
        </p>
        <p>
          You agree to indemnify, defend, and hold harmless Brainbox AI and its Resellers, their officers,
          directors, employees, agents, and licensors from and against any and all claims, liabilities,
          damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or in
          connection with your use of the Brainbox AI Dashboard in a manner not authorized by the
          Agreement.
        </p>
      </li>
      <li>
        SYSTEM REQUIREMENTS
        <p>
          You are responsible for ensuring that the computer systems upon which the Brainbox AI
          Dashboard shall be accessed as well as the telecommunication system used to establish
          connection meet the minimal requirements prescribed by Brainbox AI.
        </p>
      </li>
      <li>
        LOGIN
        <p>
          As an Authorized User of the Brainbox AI Dashboard, you will be asked to create an account.
        </p>
        <p>
          You will need to provide certain business contact information in order for Brainbox AI or its
          Reseller to send you the login access information. You will then receive a login ID giving you
          access to the Brainbox AI Dashboard. As part of the set-up process, you will be required to create
          a password and to set up multi-factor authentication.
        </p>
        <p>
          You are solely responsible to provide accurate business contact information and to keep your
          account login information confidential and secure.
        </p>
        <p>
          Once your login is set up, you are solely responsible for any activity that occurs under the
          Customer Account.
        </p>
      </li>
      <li>
        RESTRICTIONS
        <p>You warrant that you will not:</p>
        <ol class="restrictionList list-inside" type="a">
          <li>
            transfer, sell, lease, distribute, license or sublicense the Brainbox AI Dashboard, in whole
            or in part, except as expressly set forth in the Agreement; or
          </li>
          <li>
            make derivative works of, make modifications, corrections, alterations, enhancements or
            other additions to the Brainbox AI Dashboard; or  
          </li>
          <li>
            decompile, disassemble, reverse compile or reverse engineer or otherwise attempt to
            derive the source code of the whole or any portion of the Brainbox AI Dashboard; or
          </li>
          <li>
            use the Brainbox AI Dashboard as a component of or a base for products or services
            prepared for commercial sale, sublicense, lease, access, or distribution; or
          </li>
          <li>
            access the Brainbox AI Dashboard in order to build a similar or competitive product or
            service; or
          </li>
          <li>
            obfuscate, remove, or alter any of the logos, trademarks, internet links, patent or copyright
            notices, confidentiality or proprietary legends or other notices or markings that are on or
            part of the Brainbox AI Dashboard; or
          </li>
          <li>
            make the Brainbox AI Dashboard available to a person that is not authorized by your
            organization or to a third party by online services, remote dial-in or network or
            telecommunication links of any kind; or
          </li>
          <li>
            use the Brainbox AI Dashboard for any unlawful purposes, or use it to engage in any
            illegal, fraudulent, or unauthorized activity; or
          </li>
          <li>
            use the Brainbox AI Dashboard in the presence of a third party who, in the opinion of
            Brainbox AI, is a competitor of Brainbox AI; or
          </li>
          <li>
            provide, disclose, divulge or make available to, or permit use of the Brainbox AI Dashboard
            by persons other than yourself.
          </li>
        </ol>
        <p>
          These EUTU do not grant you or anyone else the right to access or obtain the source code of the
          Brainbox AI Dashboard.
        </p>
      </li>
      <li>
        CHANGES APPLIED TO BRAINBOX AI DASHBOARD
        <p>
          The Brainbox AI Services are dependent on the continuous and stable operation of the Location’s
          HVAC systems and BMS. You acknowledge that making changes to certain elements contained
          in the Brainbox AI Dashboard, whether such changes are authorized by Brainbox AI or not, such
          as changing schedules or set-point standards, could impede Brainbox AI’s ability to optimize the
          Location’s HVAC systems through the Brainbox AI Services.
        </p>
      </li>
      <li>
        OWNERSHIP AND LIMITS TO USE
        <p>
          The Brainbox AI Dashboard, including but not limited to software, graphics, text, images, and
          other content, are intellectual property rights owned by Brainbox AI or its licensors and are
          protected by intellectual property laws and regulations.
        </p>
        <p>
          You recognize and agree that all trademarks, inventions (whether patentable or not), patent
          applications, patents, works protected by copyright, trade secrets, know-how or other intellectual
          property rights in or related to the Brainbox AI Dashboard and to any software, graphics, text,
          images, and other content are the exclusive property of Brainbox AI, or its licensors, and are
          controlled by Brainbox AI.
        </p>
        <p>
          You shall not take any action that challenges the intellectual property rights of Brainbox AI,
          including but not limited to invalidity actions brought against patents owned by Brainbox AI.
        </p>
        <p>
          All rights, title and interests that are not expressly set out in these EUTU are expressly reserved
          by Brainbox AI.
        </p>
      </li>
      <li>
        CONFIDENTIALITY
        <p>
          You acknowledge that all information relating to the Brainbox AI Dashboard or that you have
          obtained or will obtain from the use of the Brainbox AI Dashboard are of a confidential nature.
          You agree to implement reasonable security measures to protect such information and to hold
          such information in confidence without disclosing it to any third party, unless otherwise provided
          in the Agreement.
        </p>
        <p>
          The Brainbox AI Dashboard and Services are not intended to accept personal information (other
          than name and basic business contact information (email)), but if you have questions regarding
          how Brainbox AI treats data that can identify any natural person or other privacy issues, please
          refer to Brainbox AI’s privacy policy, available at
          <a target="_blank" href="https://brainboxai.com/en/privacy-policy"
            >https://brainboxai.com/en/privacy-policy</a>
        </p>
      </li>
      <li>
        DISCLAIMER OF ACCURACY AND COMPLETENESS OF INFORMATION
        <p>
          Unless as otherwise provided in the Agreement, no warranty is provided as to the accuracy and
          completeness of the information contained on the Brainbox AI Dashboard. You acknowledge that
          the Brainbox AI Services are intended to help you improve energy efficiency, or monitor other
          energy-related indicators, but that Brainbox AI and its Resellers cannot guarantee specific results
          or outcomes, and that your actual energy savings may vary depending on your specific
          circumstances.
        </p>
        <p>
          The accuracy of the information contained on the Brainbox AI Dashboard depends on the
          accuracy and completeness of the Customer Data (this term is defined in the Agreement, more
          specifically in the Terms of Service) provided by you or your organization pursuant to the
          Agreement, or any data that you have inputted into the Brainbox AI Dashboard.
        </p>
        <p>
          The Measurement and Verification of energy savings in Locations displayed on the Brainbox AI
          Dashboard are based upon the standardized methodology developed by the American Society of
          Heating, Refrigerating and Air-Conditioning Engineers (ASHRAE) and the International
          Performance Measurement and Verification Protocol (IPMVP®). The estimated energy and
          emissions savings per building is based on a number of assumptions and variables that may differ
          from actual conditions.
        </p>
        <p>
          The scope emissions calculated through the provision of the autonomous decarbonization
          subscription services, if and when applicable, are based on U.S. EPA GHG Guidance and
          industry-recognized Scope 1 and Scope 2 Emissions Factors.
        </p>
      </li>
      <li>
        DISCLAIMER OF WARRANTY
        <p>
          THE BRAINBOX AI DASHBOARD IS PROVIDED "AS IS". BRAINBOX AI AND ITS RESELLERS
          DO NOT WARRANT THAT THE BRAINBOX AI DASHBOARD OR THE INFORMATION
          CONTAINED ON THE BRAINBOX AI DASHBOARD IS ERROR FREE OR THAT YOU WILL BE
          ABLE TO OPERATE THE BRAINBOX AI DASHBOARD WITHOUT PROBLEMS OR
          INTERRUPTIONS, OR THAT IT WILL BE COMPATIBLE OR WORK WITH ANY SOFTWARE,
          SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, OR FREE OF
          HARMFUL CODE. BRAINBOX AI AND ITS RESELLERS EXPRESSLY DISCLAIM ALL
          WARRANTIES, REPRESENTATIONS AND CONDITIONS, EITHER EXPRESSED OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO THE WARRANTIES OF NON-INFRINGEMENT,
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY
          AND/OR ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE WITH
          REGARD TO THE BRAINBOX AI DASHBOARD OR THE INFORMATION CONTAINED ON THE
          BRAINBOX AI DASHBOARD.
        </p>
        <p>
          BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          IMPLIED WARRANTIES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. TO THE
          EXTENT AN IMPLIED WARRANTY CANNOT BE EXCLUDED, SUCH WARRANTY IS LIMITED
          TO THE STRICTEST WARRANTY APPLICABLE IN SUCH JURISDICTION.
        </p>
      </li>
      <li>
        LIMITATION OF LIABILITY
        <p>
          IN NO EVENT SHALL BRAINBOX AI, ITS RESELLERS AND THEIR EMPLOYEES,
          DIRECTORS, OFFICERS, AND AGENTS BE COLLECTIVELY LIABLE TO YOU FOR
          DAMAGES OF ANY KIND. ANY INDEMNITY ARISING OUT OF OR IN CONNECTION WITH
          YOUR USE OF THE BRAINBOX AI DASHBOARD OR YOUR USE OF ANY INFORMATION
          CONTAINED ON THE BRAINBOX AI DASHBOARD CLAIMED IN CONTRACT, EQUITY, TORT,
          (INCLUDING NEGLIGENCE OR STRICT LIABILITY), WARRANTY OR OTHERWISE, SHALL
          BE LIMITED TO $100 IN THE AGGREGATE OR REPLACEMENT OR REPAIR OF THE
          BRAINBOX AI DASHBOARD (AT BRAINBOX AI’S SOLE OPTION).
        </p>
        <p>
          IN NO EVENT SHALL BRAINBOX AI, ITS RESELLERS AND THEIR EMPLOYEES,
          DIRECTORS, OFFICERS AND AGENTS BE LIABLE TO YOU FOR ANY SPECIAL,
          INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR FOR THE
          LOSS OF PROFIT ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE
          BRAINBOX AI DASHBOARD OR YOUR USE OF ANY INFORMATION CONTAINED ON THE
          BRAINBOX AI DASHBOARD, EVEN IF BRAINBOX AI OR ITS RESELLERS ARE ADVISED OF
          THE POSSIBILITY OF SUCH POTENTIAL LOSS OR DAMAGE.
        </p>
      </li>
      <li>
        TERMINATION
        <p>
          You understand and agree that Brainbox AI or its Resellers may terminate your access to the
          Brainbox AI Dashboard, cancel your access, and all of your rights under these EUTU will
          terminate automatically, if: (a) your organization no long holds the right to allow you to access the
          Brainbox AI Dashboard; or (b) you breach the terms of these EUTU, or applicable laws and
          regulations; or (c) you are no longer an Authorized User designated by your organization.
        </p>
        <p>
          Upon termination of your access privileges, you agree to cease to use the Brainbox AI Dashboard
          and destroy immediately all copies that you may have made.
        </p>
      </li>
      <li>
        GOVERNING LAW AND JURISDICTION
        <p>
          These EUTU shall be governed and construed in accordance with the laws in force in the Province
          of Quebec, Canada, without giving effect to principles of conflicts of law. The courts having
          jurisdiction in and for the district of Montreal in the Province of Quebec shall have exclusive
          jurisdiction in respect of any dispute related to this Agreement.
        </p>
      </li>
      <li>
        AMENDMENTS
        <p>
          Brainbox AI and its Resellers may amend these EUTUs or any section thereof, in whole or in part,
          at any time. In the event of a change, you will be prompted to accept the modified EUTU when
          next accessing the Brainbox AI Dashboard.
        </p>
      </li>
      <li>
        LANGUAGE
        <p>A French version of these EUTU precedes the English version.</p>
      </li>
      <li>
        ACCEPTANCE
        <p>
          BY CLICKING THE <strong>"I ACCEPT"</strong> BUTTON DURING THE LOGIN PROCESS TO ACCESS
          THE BRAINBOX AI DASHBOARD, OR BY SIMPLY <strong>USING</strong> THE BRAINBOX AI DASHBOARD, YOU
          ACKNOWLEDGE THAT YOU HAVE READ THESE END-USER TERMS OF USE AND YOU
          ACCEPT AND AGREE TO BE BOUND BY THE THEM, MORE PARTICULARLY, THE
          LIMITATION OF LIABILITY PROVIDED AT SECTION 11.
        </p>
        <p>
          IF YOU DO NOT AGREE WITH THESE END-USER TERMS OF USE, YOU SHOULD NOT
          ACCESS OR USE THE BRAINBOX AI DASHBOARD.
        </p>
      </li>
    </ol>
          <div class="acknowledgeSection py-8">
            <p>
              YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ THESE EUTU, UNDERSTAND
              THEM, AND AGREE TO BE BOUND BY THEIR TERMS AND CONDITIONS.
            </p>
            <p>
              VOUS RECONNAISSEZ PAR LA PRÉSENTE QUE VOUS AVEZ LU CES CONDITIONS, QUE
              VOUS LES COMPRENEZ ET QUE VOUS ACCEPTEZ D'ETRE LIÉ PAR LEURS TERMES ET
              CONDITIONS.
            </p>
            <div class="flex justify-center">
              <button
                @click="close"
                class="mt-6 mr-4 bg-white text-black hover:bg-bbred-500 hover:text-white p-2 border border-gray-200 shadow-lg rounded-md">
                {{ $t('agree') }}
              </button>
              <button
                @click="logout"
                class="mt-6 bg-white text-black hover:bg-bbred-500 hover:text-white p-2 border border-gray-200 shadow-lg rounded-md">
                {{ $t('disagree') }}
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'TermOfUsePopup',
  props: ['dateTitles'],
  methods: {
    logout() {
      this.$auth0.logout({ returnTo: window.location.origin });
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.popup-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(246, 246, 246, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup {
  background: #f6f6f6;
  box-shadow: 1px 1px 10px 1px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-height: 75%;
}

.popup-header {
  padding: 30px;
  display: flex;
}

.popup-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #000;
  font-weight: 600;
  font-size: 20px;
  justify-content: space-between;
}

.popup-body {
  color: black;
  position: relative;
  padding: 20px 30px;
}

.eutu li {
  display: list-item;
}

.eutu li::before {
  content: '';
  width: 30px;
  display: inline-block;
}
.eutu p {
  margin-top: 1rem;
}
.eutu a {
  color: revert;
  text-decoration: underline;
}
.restrictionList {
  list-style: lower-alpha;
  margin-left: 20px;
}
.restrictionList li::before {
  content: '';
  width: 30px;
  display: inline-block;
}

.btn-red {
  color: white;
  background: #e40e20;
  border: 1px solid #e40e20;
  border-radius: 5px;
}

.popup-fade-enter,
.popup-fade-leave-to {
  opacity: 0;
}

.popup-fade-enter-active,
.popup-fade-leave-active {
  transition: opacity 0.5s ease;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* .popup::-webkit-scrollbar {
        display: none;
    } */
/* Hide scrollbar for IE, Edge and Firefox */
/* IE and Edge */
/* Firefox */
/* .popup {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    }  */

.popup::-webkit-scrollb {
  width: 0.5em;
}

.popup::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.popup::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
</style>
