<template>
    <div class="top-line" role="top-line" style="transform: translate3d(0%, 0px, 0px); transition: all 300ms ease 0s;"><div class="peg"></div></div>
</template>

<style>
    .top-line {
        position: fixed;
        background: #f00 !important;
        height: 4px;
        z-index: 999;
        width: inherit;
        max-width: inherit;
    }
    .peg {
        box-shadow: 0 0 10px red, 0 0 5px red ;
    }
</style>