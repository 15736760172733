<template>
  <div class="flex flex-col divide-y">
    <span class="text-lg font-bold text-gray-500 mb-4">{{
      $t('profile-setting')
    }}</span>
    <ul class="">
      <SidebarLink
        to="/profile/information"
        icon="profile_black.svg"
        label="my-account"
        class=""
        id="myAccountLink"></SidebarLink>
      <DropdownMenu title="settings" icon="tuner.svg" height="h-16">
        <li class="flex flex-row justify-between pl-8 py-2">
          <Settings />
        </li>
      </DropdownMenu>
      <SidebarLink
        to="/profile/provider"
        icon="provider_black.svg"
        label="utility-provider-setup"
        class=""
        id="providerLink"></SidebarLink>
    </ul>
    <span class="invisible">Empty</span>
  </div>
</template>

<script>
import DropdownMenu from './DropdownMenu';
import Settings from '../views/profiles/Settings';
import SidebarLink from '../views/layouts/sidebar/SidebarLink';
export default {
  components: { DropdownMenu, Settings, SidebarLink },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
