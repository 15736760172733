export default {
  methods: {
    formatNumber(value, unit) {
      if (unit === "tCO2eq") {
        return new Intl.NumberFormat(`${this.$root.getLocale()}-CA`).format(
          value,
        );
      } else {
        return new Intl.NumberFormat(`${this.$root.getLocale()}-CA`).format(
          Math.floor(value),
        );
      }
    },
  },
};
