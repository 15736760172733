import moment from "moment";

export default {
  data() {
    return {
      userLocale: "",
    };
  },

  watch: {
    "$store.state.user"() {
      const { preferred_locale } = this.$store.state.user;
      this.userLocale = preferred_locale ? preferred_locale : "";
      this.setLocale(this.getLocale());
    },
  },

  methods: {
    getLocales() {
      return ["en", "fr"];
    },
    getLocale() {
      return this.userLocale ? this.userLocale : "en";
    },

    setLocale(locale) {
      this.$root.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
      moment.locale(locale);
    },
    hasLocale() {
      if (!this.getLocale()) {
        this.setLocale("en");
      }

      this.setLocale(this.getLocale());
    },
  },
};
