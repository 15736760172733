<template>
  <div
    class="w-full absolute top-0 right-0 z-40 flex flex-col md:w-auto md:relative md:top-auto md:right-auto md:flex-row pointer-events-none"
    :class="isVirtualBuilding ? 'block' : 'hidden'"
  >
    <button
      class="pointer-events-auto w-16 ml-auto md:w-auto md:py-auto px-4 space-x-4 py-5 text-gray-200 focus:outline-none flex items-center"
      @click="$emit('toggleOpen', 'info')"
    >
      <span class="flex items-center justify-center w-8 h-8 border border-white rounded-full">
        <span>i</span>
      </span>
      <svg class="h-4 w-4 text-bbred-500 hidden md:block">
        <use :href="menuOpen === 'info' ? '#arrow-up' : '#arrow-down'"></use>
      </svg>
    </button>
    <div
      v-if="menuOpen === 'info'"
      class="bg-gray-900 border-solid border-gray-800 border-t z-40 p-5 shadow-lg text-left text-gray-300 absolute w-full top-full md:w-96"
      v-click-outside="onClickOutside"
    >
      <p v-html="$t('virtual_building_notice', { virtualBuildingNames: virtualBuildingNames })"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderInfo',
  props: ['menuOpen'],
  data() {
    return {
      virtual_buildings: [],
      current_parent_building_id: null,
    }
  },
  computed: {
    /*
    {
      [parent_building_id]: [...building_ids],
      8: [8, 56] => 56 (building_id) has common parent_building_id
    }
     */
    isVirtualBuilding() {
      const building_id = Number(this.$route.params.slug)
      const virtual_buildings = this.virtual_buildings

      if (Object.keys(virtual_buildings).length > 0) {
        const buildings_per_parent = this.buildings_per_parent
        let isIncluded = false

        Object.keys(buildings_per_parent).forEach(vb => {
          if (buildings_per_parent[vb].includes(building_id)) {
            isIncluded = buildings_per_parent[vb].includes(building_id)
          }
        })

        return isIncluded
      }

      return false
    },
    virtualBuildingNames() {
      const building_id = Number(this.$route.params.slug)
      const current_parent_building_id = this.virtual_buildings.find(vb => vb.id === building_id).parent_building_id

      return this.virtual_buildings
        .filter(vb => vb.parent_building_id === current_parent_building_id)
        .map(({ building_name }) => building_name)
        .join(', ')
        .replace(/, ([^,]*)$/, ` ${this.$t('and')} $1`)
    },
  },
  created() {
    const { buildings } = this.$store.state
    this.virtual_buildings = buildings
      .filter(b => Number(b.parent_building_id))
      .map(({ id, parent_building_id, building_name }) => ({ id, parent_building_id, building_name }))

    this.buildings_per_parent = this.virtual_buildings
      .reduce((acc, item) => {
        if (!acc[item.parent_building_id]) {
          acc[item.parent_building_id] = []
        }

        acc[item.parent_building_id].push(item.id)

        return acc
      }, {})
  },
  methods: {
    onClickOutside () {
      this.$emit('toggleOpen', '')
    },
  },
}
</script>
