<template>
  <div class="w-full">
    <div v-if="!loading" class="flex items-center justify-between w-full">
      <label for="preferredLocale" class="block mb-2">
        {{ $t("choose_language") }}
      </label>
      <div class="relative w-32">
        <select
          v-model="currentLocale"
          name="preferredLocale"
          class="block appearance-none w-full bg-white border border-gray-200 text-gray-900 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-300"
          :class="{ 'focus:text-black': !abbUser }"
          @change="onSetPreferredLocale"
        >
          <option
            v-for="locale in locales"
            :key="locale"
            :value="locale"
            :selected="$root.getLocale() === locale"
          >
            {{ $t(locale) }}
          </option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-2 pr-4 text-gray-600"
        >
          <svg class="h-2 w-2 text-bbred-500">
            <use href="#arrow-down"></use>
          </svg>
        </div>
      </div>
    </div>

    <SimpleLoader v-else />
  </div>
</template>

<script>
import { MUTATION_UPDATE_NEXUS_USER_LOCALE } from "../../graphql";
import SimpleLoader from "@/components/SimpleLoader.vue";
import { encryptId } from "../../utils/hash-id";

export default {
  name: "Settings",
  components: {
    SimpleLoader,
  },
  computed: {
    locales() {
      return this.$root.getLocales();
    },
  },

  async created() {
    const { preferred_locale } = this.$store.getters.user;
    this.currentLocale = preferred_locale
      ? preferred_locale
      : this.$root.getLocale();
  },

  data() {
    return {
      loading: false,
      currentLocale: "",
    };
  },
  methods: {
    async onSetPreferredLocale(evt) {
      const { id } = this.$store.getters.user;
      this.loading = true;
      const encryptedId = encryptId(id)
      await this.$apollo
        .mutate({
          mutation: MUTATION_UPDATE_NEXUS_USER_LOCALE,
          variables: {
            id: encryptedId,
            preferred_locale: evt.target.value,
          },
        })
        .then(async ({ data }) => {
          this.loading = false;
          const { update_nexus_user_locale_dashboard } = data;

          this.$root.setLocale(update_nexus_user_locale_dashboard.preferred_locale);
          await this.$store.dispatch("get_user_data", { auth0: this.$auth0 });
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
