<template>
  <div
    id="header-buildings"
    class="w-full flex items-center relative md:border-r md:border-solid md:border-gray-800 m-6 mt-0 md:m-0 bg-gray-900"
  >
    <button
      class="w-full flex items-center hover:text-bbred-500 px-4 py-3 outline-none focus:outline-none"
      :class="[{ 'text-white': !abbUser }]"
      @click="$emit('toggleOpen', 'buildings')"
    >
      <span class="flex items-center flex-grow md:w-72">
        <svg class="h-6 w-6 flex-shrink-0">
          <use href="#pin"></use>
        </svg>
        <span class="px-6 text-sm text-left leading-tight block">
          <div v-if="hasBuildingSlug && currentBuilding">
            <span class="text-xs block uppercase">
              {{ $t('current_building') }}:
            </span>
            <span class="block uppercase font-semibold">
              {{ buildingName }}
            </span>
          </div>
          <span v-else>
            {{ $t('choose_building') }}
          </span>
        </span>
      </span>
      <svg class="block h-4 w-4 text-bbred-500">
        <use
          :href="menuOpen === 'buildings' ? '#arrow-up' : '#arrow-down'"
        ></use>
      </svg>
    </button>
    <div
      v-if="menuOpen === 'buildings'"
      class="flex flex-col absolute top-full w-full bg-gray-900 z-40 shadow-lg max max-h-100"
      v-click-outside="onClickOutside"
    >
      <!-- Search input -->
      <div class="flex flex-grow items-center justify-between p-4 bg-gray-933">
        <input
          v-model="searchInput"
          class="w-full bg-transparent outline-none"
          :class="{
            'placeholder-gray-300': !abbUser,
            'text-gray-300': !abbUser,
            'placeholder-black': abbUser,
          }"
          type="text"
          :placeholder="$t('search')"
        />
        <svg
          class="h-5 w-5 flex-shrink-0 ml-3"
          :class="{ 'text-gray-300': !abbUser, 'text-black': abbUser }"
        >
          <use href="#search"></use>
        </svg>
      </div>
      <!-- /Search input -->
      <div class="custom-scrollbar overflow-y-auto max-h-sm -mt-px">
        <div
          v-for="(building, index) in filteredBuildings"
          :key="index"
          class="p-5 border-t border-gray-800 flex justify-between hover:bg-gray-800 transition-colors duration-300"
          :class="{
            'hover:bg-gray-900':
              currentBuilding && building.id == currentBuilding.id,
            'text-white': !abbUser,
          }"
        >
          <div class="text-sm">
            <span class="block mb-2 text-base">
              {{
                setBuildingName(
                  building,
                  setBuildingType(
                    $store.state.building_types,
                    building.building_type
                  )
                )
              }}
            </span>
            <span v-if="building.address_line" class="block">{{
              building.address_line
            }}</span>
            <span class="capitalize">{{ setLocation(building.city) }}</span>
          </div>
          <a
            @click="onClickBuilding(building.id)"
            class="flex justify-between self-center border-2 border-bbred-500 text-bbred-500 text-xs px-2 py-2 ml-4 lg:ml-0 transition-all duration-300 cursor-pointer"
            :class="{
              'opacity-25':
                currentBuilding && building.id == currentBuilding.id,
              'hover:text-white': !abbUser,
              'hover:border-white': !abbUser,
            }"
          >
            <svg class="h-3 w-3 flex-shrink-0">
              <use href="#arrow-right"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils';

export default {
  name: 'HeaderBuildings',
  props: ['menuOpen'],
  data: () => ({
    currentBuilding: null,
    searchInput: '',
    filteredBuildings: [],
  }),
  computed: {
    hasBuildingSlug() {
      const { buildings } = this.$store.state;
      const { slug } = this.$route.params;
      if (slug && buildings.length > 0) {
        this.setCurrentBuilding(slug);
      } else {
        this.setCurrentBuilding(null);
      }
      return !!slug;
    },
    buildingName() {
      const building_type = this.setBuildingType(
        this.$store.state.building_types,
        this.currentBuilding.building_type
      );
      const building_name = this.setBuildingName(
        this.currentBuilding,
        building_type
      );
      return building_name;
    },
  },
  watch: {
    searchInput: debounce(function () {
      this.filterBuildings();
    }, 200),
    '$store.state': {
      immediate: true,
      deep: true,
      handler(state) {
        const { buildings } = state;

        if (buildings.length > 0) {
          this.filteredBuildings = buildings;
        }
      },
    },
  },
  methods: {
    filterBuildings() {
      const { buildings, user } = this.$store.state;
      const is_anonymous = user.permissions.includes('read:anonymous');
      this.filteredBuildings = buildings.filter((building) => {
        const name = building.building_name.toLowerCase();
        // TODO: upgrade node (v14+) on QA and PROD
        // So we can use Optional Chaining: building.address_line?.toLowerCase()
        const address = !is_anonymous
          ? building.address_line
            ? building.address_line.toLowerCase()
            : ''
          : '';

        const ipt = this.searchInput.toLowerCase();

        if (name.includes(ipt) || address.includes(ipt)) {
          return building;
        }
      });
    },
    onClickBuilding(id) {
      if (this.currentBuilding && id === this.currentBuilding.id) return;

      this.$emit('toggleOpen', '');
      this.$router.push({ name: 'building-profile', params: { slug: id } });
    },
    onClickOutside() {
      this.$emit('toggleOpen', '');
    },
    setCurrentBuilding(slug) {
      this.currentBuilding = this.$store.state.buildings.find(
        (building) => building.id === Number(slug)
      );
    },
    setLocation(context) {
      const city = context && context.city ? context.city.toLowerCase() : '';
      const state =
        context && context.province ? context.province.toLowerCase() : '';
      const country =
        context && context.country ? context.country.toLowerCase() : '';
      return [city, state, country]
        .filter((location) => location !== '')
        .join(', ');
    },
  },
};
</script>

<style lang="stylus">


.custom-scrollbar::-webkit-scrollbar
  width 12px

.custom-scrollbar
  scrollbar-width thin
</style>
