<template>
  <div class="bg-gray-100 fixed top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center justify-center w-full">
  <img v-if="abbUser" class="block w-56" src="~@/assets/img/abb-logo.png" alt="" />
  <img v-else src="@/assets/icons/logos/BBAI_TrademarkLogo_Grey-Red.svg" alt="">
 

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="loading h-6 w-6 mt-4 text-gray-400"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round">
      <circle class="text-gray-700" cx="12" cy="12" r="10"></circle>
      <circle class="loading-circle" cx="12" cy="12" r="10"></circle>
    </svg>
  </div>
</template>

<script>
import BrainBoxLogo from '@/components/BrainBoxLogo'



export default {
  name: 'Loader',
  components: { BrainBoxLogo },
}
</script>

<style lang="stylus">
.loading
  animation 1.25s linear infinite svg-animation

  .loading-circle
    stroke-dasharray 62
    stroke-dashoffset 31
    transform-origin 50% 50%

// SVG animation.
@keyframes svg-animation
  0%
    transform rotateZ(0deg)

  100%
    transform rotateZ(360deg)
</style>
