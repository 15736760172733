<template>
  <div
    class="sidebar font-bold text-base bg-white overflow-y-auto"
    :style="{
      width: window.width > 768 ? sidebarWidth : mobileMenuWidth,
    }"
  >
    <nav id="sideBarNav" class="flex flex-col">
      <div class="logo sticky bg-white top-0 z-50">
        <div
          v-if="!abbUser"
          class="mb-4 px-4 py-4 flex h-20 items-center justify-between"
        >
          <!--In Desktop we deal with collapsed/uncollapsed, but not in mobile-->
          <img
            v-if="!collapsed"
            class="w-60"
            src="@/assets/icons/logos/BBAI_TrademarkLogo_Grey-Red.svg"
            alt=""
          />
          <img
            v-show="!isMobile"
            v-else
            class="w-9 h-7 ml-2"
            src="@/assets/img/ai-disabled.png"
            alt=""
          />

          <!--Only dealing with collapse on desktop-->
          <span
            v-if="!isMobile"
            class="collapse-icon block"
            @click="toggleSidebar"
            :class="{ 'rotate-180': collapsed }"
          >
            <img
              class="w-5 h-5 max-w-none z-50"
              src="@/assets/icons/chevron_left_black.svg"
              alt=""
            />
          </span>

          <!-- Mobile Menu Close Button-->
          <span v-else>
            <button @click="$emit('toggleMobileMenu')">
              <img
                class="w-5 h-5 max-w-none z-50"
                src="@/assets/icons/close_black.svg"
                alt=""
              />
            </button>
          </span>
        </div>

        <!--ABB Section here-->
        <div v-else class="mb-6 pt-6 mx-12 w-fit">
          <div class="flex h-16 items-center">
            <img class="w-4/5" src="@/assets/img/abb-logo.png" alt="" />
          </div>

          <p v-show="!collapsed" class="text-xs font-semibold">
            ABB Ability &trade; {{ $t("abb-trademark") }}
          </p>
          <p v-show="!collapsed" class="text-xs font-normal">
            {{ $t("powered-by") }}
          </p>
        </div>
      </div>

      <div class="flex flex-col my-1">
        <SidebarLink
          v-if="userRole.includes('aria')"
          to="/aria"
          icon="globe.svg"
          label="ARIA"
          class="border border-gray-500 mb-4"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="ariaLink"
        >
        </SidebarLink>
        <SidebarLink
          to="/portfolio"
          icon="roofing_black.svg"
          label="portfolio"
          class="border border-gray-500 mb-4"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="portfolioLink"
        >
        </SidebarLink>
        <SidebarLink
          v-if="
            this.userRole.includes('msr') &&
            this.userRole.includes('building_management')
          "
          to="/building-management"
          icon="building-mgmt-icon.svg"
          label="building-management"
          class="border border-gray-500 mb-4"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="portfolioLink"
        >
        </SidebarLink>
        <SidebarLink
          v-if="userRole.includes('esg_manager')"
          to="/integrations"
          icon="apps_black.svg"
          label="Integrations"
          class="border border-gray-500"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="integrationsLink"
        ></SidebarLink>
        <!-- menu for alarm management -->
        <!-- <SidebarLink
          to="/alarm-management"
          icon="alarm-icon.svg"
          label="alarm-management"
          class="border border-gray-500"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="portfolioLink">
        </SidebarLink> -->
        <div class="borderBottom"></div>
        <SidebarItems />
        <div class="borderBottom"></div>
        <span
          v-if="!collapsed"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          class="text-red-500 mb-2"
          >{{ $t("support") }}</span
        >
        <SidebarLink
          to="/contact-us"
          icon="feedback_black.svg"
          label="contact-us"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="contactUsLink"
        >
        </SidebarLink>
        <!-- Removed for now -->
        <!-- <SidebarLink
          to="/profile/notification-center"
          icon="release_notes_black.svg"
          label="notification-center-release"
          :class="abbUser ? 'mx-12' : 'mx-4'"
          id="releaseNoteLink">
        </SidebarLink> -->
        <div class="borderBottom"></div>
      </div>
    </nav>
    <div
      v-if="!collapsed"
      class="flex flex-col copyright items-center bottom-10 mx-6 font-normal select-none"
    >
      <span
        >© <span class="mr-2">{{ currYear }} BrainBox AI</span></span
      >
      <router-link to="/term-of-use" class="hover:text-red-400">{{
        $t("end-user-terms")
      }}</router-link>
    </div>
  </div>
</template>

<script>
import SidebarLink from "./SidebarLink";
import SidebarItems from "./SidebarItems";
import { collapsed, toggleSidebar, sidebarWidth } from "./state";

export default {
  props: ["mobileMenuOpen"],
  components: { SidebarLink, SidebarItems },
  setup() {
    return { collapsed, toggleSidebar, sidebarWidth };
  },

  computed: {
    mobileMenuWidth() {
      return this.mobileMenuOpen ? `${this.window.width - 70}px` : 0;
    },
  },

  data() {
    return {
      currYear: new Date().getFullYear(),
      userRole: [],
    };
  },
  created() {
    const { roles } = this.$store.state.user;
    this.userRole = roles;
  },

  watch: {
    "window.width"(val) {
      if (val <= 768) {
        if (this.collapsed) this.toggleSidebar();
      } else if (val <= 1280) {
        if (!this.collapsed) this.toggleSidebar();
      }
    },
  },
};
</script>

<style>
.sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  height: 100%;
  transition: all 0.3s ease;
}
.borderBottom {
  height: 1px;
  background: #dcdada;
  width: 100%;
  margin: 30px 0 20px;
}
.collapse-icon {
  bottom: 0;
  margin-left: 0.5rem;
  transition: 0.2s linear;
}
.rotated {
  transform: rotate(-90deg);
  transition: 0.2s linear;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
