<template>
    <nav id="buildingMenuList" class="submenu flex flex-col">
        <div v-for="link in links">
            <div v-if="link.hasRole">
              <router-link
              v-if="!link.children"
              :to="{ name: link.name, params: { slug: hasBuildingSlug? $route.params.slug : '239' } }"
              :key="link.name"
              v-slot="{ isActive, href, navigate }"
              > 
              
                <a
                  :href="href"
                  @click="navigate"
                  class="link flex flex-row items-center cursor-pointer justify-between relative font-normal text-base select-none p-1 h-12 hover:bg-black hover:text-white"
                  :class="[ isActive ? 'active text-white bg-black delay-0': '']"
                  :id="link.name + '-link'"
                >  
                  <div class="flex flex-row">
                    <img class="icon shrink-0 mx-2" :src="getImgUrl(link.icon)">
                    <transition class="fade">
                      <span v-if="!collapsed">
                        {{ $t(link.name) }}
                      </span>
                    </transition>
                  </div> 
                  <!-- <div v-if="collapsed" class="icon-label text-base items-center flex p-4">{{ $t(link.label) }}</div> -->
                </a>
              </router-link>
              <div v-else>
                <div
                @click="showSubMenu = !showSubMenu"
                  id="buildingSubMenu"
                  class="flex flex-row items-center cursor-pointer relative font-normal text-base select-none p-1 h-12 menu-title hover:font-bold"> 
                    <div class="flex flex-row">
                        <img class="icon shrink-0 mx-2" :src="getImgUrl(link.icon)">
                        <transition class="fade">
                            <span v-if="!collapsed">{{ $t(link.name) }}</span>
                        </transition>
                    </div>
                    <img v-if="!collapsed"  class="collapse-icon" :src="getImgUrl('expand_more_black.svg')" :class="{'rotated': !showSubMenu }">
                    <!-- <div v-if="collapsed" class="icon-label text-base items-center flex p-4">{{ $t(link.label) }}</div> -->
                </div>
                <transition name="expand">
                  <SideBarMenuLv3 v-if="showSubMenu && !collapsed " class="overflow-hidden ml-4"/>
                </transition>
              </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { collapsed } from './state';
import SideBarMenuLv3 from './SideBarMenuLv3.vue';
export default {
  name: 'submenu',
  components: { SideBarMenuLv3 },
  props: ['links'],
  setup(props) {
    return { collapsed }
  },
  
  methods: {
    getLevel2RouteName(name) {
      const match = this.links.find(
        (link) => link.name === name
      );
      return (match || this.links[0]).name;
    },
    getImgUrl: function (img) { 
        return require('@/assets/icons/' + img);
    },
  },
  data() {
    const { name } = this.$route;
    return {
      selectedOption: this.getLevel2RouteName(name),
      showSubMenu: (this.$route.path.includes('scope') || this.$route.path.includes('ghg-emissions')) ? true : false
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.selectedOption = this.getLevel2RouteName(to.name);
      },
      handler(path) {
        if(path.path.includes('ghg-emissions') || path.path.includes('scope')){
          this.showSubMenu = true
        }else {
          this.showSubMenu = false
        }
      },
      deep: true,
    },
  },
  computed: {
    hasBuildingSlug() {
      return (
        this.$route.params &&
        this.$route.params.slug &&
        this.$route.path.includes('buildings')
      );
    },
  },
};
</script>

<style scoped>
.link {
    border-radius: 0.5rem;
}
.link:hover img{
    filter: invert();
}

.link .icon-label {
    opacity: 0;
    float: left;
    left: 50px;
    width: 0px;
    height: 100%;
    position: absolute;
    transition: width 0.1s;
    background-color: black;
    border-radius: 0.5rem;
}

.link:hover .icon-label {
    width: 150px;
    opacity: 1;
}
.active {
    background-color: black;
    color: white;
}
.active img{
    filter: invert();
}
.expand-enter-active,
.expand-leave-active {
  transition: max-height .5s ease;
  max-height: 400px;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}

</style>