<template>
  <div class="flex flex-col items-center justify-center w-full">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="loading h-6 w-6 mt-4 text-gray-400"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <circle class="text-gray-700" cx="12" cy="12" r="10"></circle>
      <circle class="loading-circle" cx="12" cy="12" r="10"></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SimpleLoader',
};
</script>

<style lang="stylus" scoped>
.loading
  animation 1.25s linear infinite svg-animation

  .loading-circle
    stroke-dasharray 62
    stroke-dashoffset 31
    transform-origin 50% 50%

@keyframes svg-animation
  0%
    transform rotateZ(0deg)

  100%
    transform rotateZ(360deg)
</style>
