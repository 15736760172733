<template>
  <span
    v-if="!collapsed"
    :class="abbUser ? 'mx-12' : 'mx-4'"
    class="text-red-500 mb-2"
    >MENU</span
  >
  <div
    class="menu-item"
    :class="{ 'disabled text-gray-500': !hasBuildingSlug }"
    @mousemove="mouseMove">
    <div
      @click="toggleMenu()"
      :class="{ opened: showChildren, '!mx-10': abbUser }"
      class="link flex flex-row items-center cursor-pointer relative font-normal mx-4 text-base select-none p-1 h-12 menu-title hover:font-bold">
      <div class="flex flex-row">
        <img
          class="icon shrink-0 mx-2"
          :src="getImgUrl('building_black.svg')" />
        <transition class="fade">
          <span v-if="!collapsed">{{ $t('building') }}</span>
        </transition>
      </div>
      <img
        v-if="!collapsed"
        class="collapse-icon"
        :src="getImgUrl('expand_more_black.svg')"
        :class="{ rotated: !showChildren }" />
    </div>
    <ul
      v-if="showChildren"
      :class="collapsed ? 'mx-4' : !abbUser ? 'mx-6' : 'mx-12'"
      class="items-container"
      id="buildingMenu"
      ref="container"
      :style="{ height: containerHeight }">
      <SideBarMenuLv2
        :links="
          this.$route.path.includes('decarb-buildings') ? decarbLinks : links
        " />
    </ul>
    <span
      id="tooltip-span"
      class="tooltip-span max-w-xs rounded-md font-normal text-black bg-gray-200 p-2">
      Select a building from your portfolio to access building-specific data.
    </span>
  </div>
</template>

<script>
import { collapsed } from './state';
import SideBarMenuLv2 from './SideBarMenuLv2';

export default {
  name: 'menu-item',
  data: () => ({
    showChildren: true,
    containerHeight: 'fit-content',
  }),
  props: {},
  setup(props) {
    return { collapsed };
  },

  components: { SideBarMenuLv2 },
  methods: {
    getImgUrl: function (img) {
      return require('@/assets/icons/' + img);
    },
    toggleMenu() {
      //if menu is closed
      if (!this.showChildren) {
        this.showChildren = true;
        this.$nextTick(() => {
          //we get the height of what is inside the container to start the animation
          this.containerHeight = this.$refs['container'].scrollHeight + 'px';

          setTimeout(() => {
            this.containerHeight = 'fit-content';
            this.$refs['container'].style.overflow = 'visible';
          }, 300); //duration of the animation
        });
      } else {
        this.containerHeight = this.$refs['container'].scrollHeight + 'px';
        this.$refs['container'].style.overflow = 'hidden';
        setTimeout(() => {
          this.containerHeight = 0 + 'px';
        }, 10);
        setTimeout(() => {
          this.showChildren = false;
        }, 300);
      }
    },
    mouseMove(event) {
      var tooltipSpan = document.getElementById('tooltip-span');
      var x = event.clientX;
      var y = event.clientY;
      tooltipSpan.style.top = y + 20 + 'px';
      tooltipSpan.style.left = x + 20 + 'px';
    },
  },
  computed: {
    links() {
      return [
        {
          label: 'Profile',
          name: 'building-profile',
          icon: 'fact_check_black.svg',
          hasRole: true,
        },
        {
          label: 'Control Status',
          name: 'control-status',
          icon: 'tuner_black.svg',
          hasRole: true,
        },
        {
          label: 'Command Log',
          name: 'command_log',
          icon: 'terminal.svg',
          hasRole: true,
        },
        {
          name: 'energy',
          label: 'Energy',
          icon: 'electric_bolt_black.svg',
          hasRole: true,
        },
        {
          name: 'emissions',
          label: 'Emissions',
          icon: 'eco_leaf.svg',
          hasRole: true,
        },
        //removed for MSR release
        // {
        //   name: 'comfort',
        //   label: 'Comfort',
        //   icon: 'thermometer_black.svg',
        //   hasRole: true,
        // },
        {
          name: 'equipment-list',
          label: 'Equipment List',
          icon: 'info_black.svg',
          hasRole: this.canViewMSR,
        },
        //hide for release
        {
          name: 'emissions-reporting',
          label: 'emissions-reporting',
          icon: 'description.svg',
          hasRole: this.canViewEmissions,
          children: [
            {
              name: 'scope-summary',
              label: 'Summary',
              icon: 'cloud_black.svg',
            },
            {
              name: 'scope-scope1',
              label: 'Scope1',
              icon: 'cloud_black.svg',
            },
            {
              name: 'scope-scope2',
              label: 'Scope2',
              icon: 'cloud_black.svg',
            },
            {
              name: 'faq',
              label: 'FAQ',
              icon: 'cloud_black.svg',
            },
          ],
        },
        {
          label: 'Release AI',
          name: 'release',
          icon: 'legend_toggle_black.svg',
          hasRole: this.canViewRelease,
        },
      ];
    },

    decarbLinks() {
      return [
        {
          name: 'edit-decarb',
          label: 'Edit Decarb',
          icon: 'edit_black.svg',
          hasRole: true,
        },
        {
          name: 'emissions-reporting',
          label: 'Emissions Reporting',
          icon: 'cloud_black.svg',
          hasRole: true,
          children: [
            {
              name: 'scope-summary',
              label: 'Summary',
              icon: 'cloud_black.svg',
            },
            {
              name: 'scope-scope1',
              label: 'Scope1',
              icon: 'cloud_black.svg',
            },
            {
              name: 'scope-scope2',
              label: 'Scope2',
              icon: 'cloud_black.svg',
            },
          ],
        },
      ];
    },

    //emissions logic
    canViewEmissions() {
      const { roles } = this.$store.getters['user'];
      if (roles.includes('esg_manager')) return true;

      return false;
    },

    //msr logic
    canViewMSR() {
      const { roles } = this.$store.getters['user'];
      if (roles.includes('msr')) return true;

      return false;
    },

    //release page conditional logic
    canViewRelease() {
      const { buildings } = this.$store.getters['user'];
      const slug = this.$route.params
        ? this.$route.params.slug
          ? this.$route.params.slug
          : null
        : null;

      //add for Dev Lab
      if (+slug === 33) {
        return true;
      }

      const currentBuilding = buildings.find((ub) => ub.id == slug);

      if (!currentBuilding) {
        return false;
      }

      const { permissions } = currentBuilding;

      if (!permissions.length) {
        return false;
      }

      if (permissions.find((p) => p === 'write:release')) return true;

      return false;
    },

    hasBuildingSlug() {
      return (
        this.$route.params &&
        this.$route.params.slug &&
        this.$route.path.includes('buildings')
      );
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave {
  opacity: 0;
}
.link {
  justify-content: space-between;
  border-radius: 0.5rem;
}
.link:hover img {
  filter: invert();
}

.icon-label {
  opacity: 0;
  float: left;
  left: 50px;
  width: 0px;
  height: 100%;
  position: absolute;
  transition: width 0.1s;
  background-color: black;
  border-radius: 0.5rem;
}

.link:hover .icon-label {
  width: 150px;
  opacity: 1;
}
.items-container {
  transform: 0.2 ease;
  overflow: hidden;
  transition: height 0.3s ease;
}
.items-container .link {
  margin: 0;
}
.active {
  background-color: black;
  color: white;
}
.active img {
  filter: invert();
}

.opened {
  font-weight: 700;
}
.menu-title:hover img {
  filter: none !important;
}

.disabled .active {
  background-color: white !important;
  color: #838383 !important;
}
.disabled .active:hover {
  background-color: white !important;
  color: #838383 !important;
}
.disabled .active img {
  filter: none;
}
.menu-item .tooltip-span {
  display: none;
}
.disabled .link {
  pointer-events: none;
}
.disabled .items-container {
  pointer-events: none;
}
.disabled:hover .tooltip-span {
  display: block;
  position: fixed;
  overflow: hidden;
}
</style>
