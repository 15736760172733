import gql from "graphql-tag";

export const QUERY_DEVICE_IDS = gql`
  query getDevices($building_id: Int) {
    devices(building_id: $building_id) {
      device_name
      id
      status
    }
  }
`;
export const QUERY_MONITORING_ALGORITHMS = gql`
  query getMonitoringAlgorithms($device_id: Int) {
    monitoringalgs(device_id: $device_id) {
      id
      device_id
      alg_name
      status
    }
  }
`;

export const QUERY_CLIENTS_BY_IDS = gql`
  query getClientsByIds($client_ids: [String]) {
    get_clients_by_ids_dashboard(client_ids: $client_ids) {
      id
      code
      name
      status
    }
  }
`;

export const QUERY_CLIENT_RELEASES = gql`
  query getClientReleases($device_ids: [Int!]!) {
    client_releases(device_ids: $device_ids) {
      id
      device_id
      building
      status
      description
      release_requested
      reengagement_date
      release_reason
    }
  }
`;

export const MUTATION_RELEASE_REQUESTED = gql`
  mutation updateReleaseRequested(
    $id: Int
    $device_id: Int
    $input: update_release_input!
  ) {
    update_release_requested(id: $id, device_id: $device_id, input: $input) {
      id
      status
      release_requested
      description
      reengagement_date
      release_reason
    }
  }
`;

export const MUTATION_RELEASE_REENGAGEMENT = gql`
  mutation updateReleaseReengagement(
    $id: Int
    $device_id: Int
    $input: update_reengagement_input!
  ) {
    update_release_reengagement(id: $id, device_id: $device_id, input: $input) {
      id
      status
      release_requested
      description
      reengagement_date
      release_reason
    }
  }
`;

export const MUTATION_UPDATE_NEXUS_USER_LOCALE = gql`
  mutation updateNexusUserLocale($id: String, $preferred_locale: String) {
    update_nexus_user_locale_dashboard(id: $id, preferred_locale: $preferred_locale) {
      id
      preferred_locale
    }
  }
`;

export const QUERY_DEVICES_BY_BUILDING = gql`
  query getDevicesByBuilding($building_id: Int!) {
    get_devices_by_building(building_id: $building_id) {
      id
      building_id
      device_name
      device_label
      status
      leo_legacy
    }
  }
`;

export const QUERY_ALGORITHM_STATUS = gql`
  query getAlgorithmStatus($building_id: Int!) {
    get_algorithm_status(building_id: $building_id) {
      id
      building_id
      algorithm
      status
    }
  }
`;

export const QUERY_COMFORT = gql`
  query getComfort($building_id: Int!) {
    comfort(building_id: $building_id) {
      building_id
      ai_enabled_comfort
      ai_enabled_sample_size
      ai_enabled_confidence
      ai_disabled_comfort
      ai_disabled_sample_size
      ai_disabled_confidence
      exclude
      messages
    }
  }
`;

export const QUERY_COMFORT_HISTORY = gql`
  query getComfortHistory($building_id: Int!) {
    comfort_history(building_id: $building_id) {
      building_id
      ai_enabled_comfort
      ai_enabled_sample_size
      ai_enabled_confidence
      ai_disabled_comfort
      ai_disabled_sample_size
      ai_disabled_confidence
      month
      exclude
      messages
    }
  }
`;

export const QUERY_BUILDINGS_BY_ID = gql`
  query getBuildingsById($building_ids: [Int!]!, $is_anonymous: Boolean!) {
    get_buildings_by_id(building_ids: $building_ids) {
      id
      building_type
      parent_building_id
      client_id
      building_name @skip(if: $is_anonymous)
      building_label @skip(if: $is_anonymous)
      address_line @skip(if: $is_anonymous)
      postal_code @skip(if: $is_anonymous)
      longitude @skip(if: $is_anonymous)
      latitude @skip(if: $is_anonymous)
      square_footage @skip(if: $is_anonymous)
      deployment_type @skip(if: $is_anonymous)

      # relationships
      city {
        city
        province
        country
      }
    }
  }
`;

export const QUERY_BUILDING_LISTING = gql`
  query getBuildingListing($building_ids: [Int!]!, $is_anonymous: Boolean!) {
    get_buildings(building_ids: $building_ids) {
      id
      building_type
      status
      parent_building_id
      building_name @skip(if: $is_anonymous)
      building_label @skip(if: $is_anonymous)
      address_line @skip(if: $is_anonymous)
      postal_code @skip(if: $is_anonymous)
      longitude @skip(if: $is_anonymous)
      latitude @skip(if: $is_anonymous)
      database @skip(if: $is_anonymous)
      db_hostname @skip(if: $is_anonymous)

      # relationships
      city {
        city
        province
        country
      }
    }
  }
`;

export const QUERY_BUILDING = gql`
  query getBuilding($building_id: Int!, $is_anonymous: Boolean!) {
    get_building(building_id: $building_id) {
      address_line
      id
      building_type
      progress
      parent_building_id
      building_name @skip(if: $is_anonymous)
      building_label @skip(if: $is_anonymous)
      database
      longitude
      latitude
      db_hostname
      deployment_type
      city {
        city
        province
        country
      }
    }
  }
`;

export const QUERY_ENERGY_ELECTRICITY = gql`
  query getConsumptionElectricity($building_id: Int!) {
    energy_electricity(building_id: $building_id) {
      expected_consumption
      actual_consumption
      saving_kwh
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    energy_electricity_history(building_id: $building_id) {
      month
      expected_consumption
      actual_consumption
      saving_kwh
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_ENERGY_GAS = gql`
  query getConsumptionGas($building_id: Int!) {
    energy_gas(building_id: $building_id) {
      expected_consumption
      actual_consumption
      saving_volume
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    energy_gas_history(building_id: $building_id) {
      month
      expected_consumption
      actual_consumption
      saving_volume
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_ENERGY_POWER = gql`
  query getConsumptionPower($building_id: Int!) {
    energy_power(building_id: $building_id) {
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    energy_power_history(building_id: $building_id) {
      month
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_MONETARY = gql`
  query getMonetary($building_id: Int!) {
    monetary(building_id: $building_id) {
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    monetary_history(building_id: $building_id) {
      month
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_MONETARY_ELECTRICITY = gql`
  query getMonetaryElectricity($building_id: Int!) {
    monetary_electricity(building_id: $building_id) {
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    monetary_electricity_history(building_id: $building_id) {
      month
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_MONETARY_GAS = gql`
  query getMonetaryGas($building_id: Int!) {
    monetary_gas(building_id: $building_id) {
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    monetary_gas_history(building_id: $building_id) {
      month
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_MONETARY_POWER = gql`
  query getMonetaryPower($building_id: Int!) {
    monetary_power(building_id: $building_id) {
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }

    monetary_power_history(building_id: $building_id) {
      month
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_EMISSIONS = gql`
  query getEmissions($building_id: Int!) {
    emissions_history(building_id: $building_id) {
      month
      expected
      actual
      saving
      saving_percentage
      update_time_utc
      messages
      exclude
      marginal_rate
    }
  }
`;

export const QUERY_EMISSIONS_TEST = gql`
  query getEmissionsTest($building_id: Int!) {
    emissions_history_test(building_id: $building_id) {
      month
      average_expected
      average_actual
      average_saving
      average_operating_rate
      saving_percentage
      marginal_operating_rate
      marginal_average_ratio
      marginal_saving
      messages
      exclude
      creation_time_utc
      update_time_utc
    }
  }
`;

export const QUERY_BUILDING_PROFILE = gql`
  query getBuildingProfile(
    $building_id: Int!
    $year_start: String!
    $year_end: String!
  ) {
    get_building(building_id: $building_id) {
      id
      address_line
      status
      progress
      longitude
      latitude
      building_name
      building_type
      deployment_type
      city {
        city
        province
        country
      }
    }

    comfort(building_id: $building_id) {
      ai_enabled_comfort
    }

    virtual_metering(
      building_id: $building_id
      start_date: $year_start
      end_date: $year_end
      saving_unit: "all"
    ) {
      id
      mv_date
      exclude
      actual
      saving_value
      saving_unit
    }

    vm_estimated(
      building_id: $building_id
      start_date: $year_start
      end_date: $year_end
      saving_unit: "all"
    ) {
      saving_value
      saving_unit
    }

    vm_estimated_filtered(
      building_id: $building_id
      start_date: $year_start
      end_date: $year_end
      saving_unit: "all"
    ) {
      saving_value
      saving_unit
    }

    get_devices_by_building(building_id: $building_id) {
      id
    }

    # get_algorithm_status(building_id: $building_id) {
    #   id
    #   building_id
    #   algorithm
    #   status
    # }
  }
`;

export const QUERY_CONTROL_STATUS = gql`
  query getControlStatus($building_id: Int!) {
    control_status(building_id: $building_id) {
      area_type
      label
      controlled_amount
      total_amount
      enumeration {
        tag
      }
    }
  }
`;

export const QUERY_FEATURE_RELEASES = gql`
  query getFeatureReleases($type: String) {
    get_feature_releases(type: $type) {
      id
      release_number
      release_date
      feature
      description
      feature_fr
      description_fr
      active
      created_time_utc
      updated_time_utc
    }
  }
`;

export const QUERY_ALL_FUEL_TYPES = gql`
  query getAllFuelTypes($fuel_id: [Int], $is_mobile: Boolean) {
    get_all_fuel_types(fuel_id: $fuel_id, is_mobile: $is_mobile) {
      fuel_id
      fuel
    }
  }
`;

export const QUERY_UNITS_BY_FUEL_TYPES = gql`
  query getUnitsByFuelTypes($fuel: String!) {
    get_units_by_fuel_types(fuel: $fuel) {
      fuel
      unit
    }
  }
`;

export const QUERY_UNITS_BY_TYPE = gql`
  query getUnitsByType($unit_type: String!) {
    get_units_by_type(unit_type: $unit_type) {
      unit
    }
  }
`;

export const QUERY_MODES_OF_TRANSPORT = gql`
  query getModesOfTransport {
    get_modes_of_transport {
      mode_of_transport
    }
  }
`;

export const QUERY_MOBILE_EMISSIONS_FACTORS = gql`
  query getMobileEmissionsFactors(
    $mode_of_transport: String
    $vehicle_type: String
  ) {
    get_mobile_emissions_factors(
      mode_of_transport: $mode_of_transport
      vehicle_type: $vehicle_type
    ) {
      mode_of_transport
      vehicle_type
      model
      fuel_type
      gCH4_per_mile
      gN2O_per_mile
    }
  }
`;

export const QUERY_REFRIGERANTS = gql`
  query getAllRefrigerants {
    get_all_refrigerants {
      id
      refrigerant
    }
  }
`;

export const QUERY_MESSAGES = gql`
  query getMessages($ids: [Int]) {
    get_messages(ids: $ids) {
      id
      en
      fr
    }
  }
`;

export const QUERY_NOTIFICATION = gql`
  query getNotification($user: String!) {
    get_notification(user: $user) {
      id
      user
      read_notifications
    }
  }
`;
export const MUTATION_CREATE_NOTIFICATION = gql`
  mutation createNotification($input: create_notification_input!) {
    create_notification(input: $input) {
      id
      user
      read_notifications
    }
  }
`;

export const MUTATION_UPDATE_NOTIFICATION = gql`
  mutation updateNotification($id: ID, $input: update_notification_input!) {
    update_notification(id: $id, input: $input) {
      id
      user
      read_notifications
    }
  }
`;

export const QUERY_PORFOLIO_KPIS = gql`
  query getAllKpis($building_ids: [Int!]) {
    all_electricity(building_ids: $building_ids) {
      expected_consumption
      actual_consumption
      saving_kwh
      saving_percentage
      exclude
    }
    all_gas(building_ids: $building_ids) {
      expected_consumption
      actual_consumption
      saving_volume
      saving_percentage
      exclude
    }
    all_monetary(building_ids: $building_ids) {
      expected
      actual
      saving
      saving_percentage
      exclude
    }
    all_power(building_ids: $building_ids) {
      expected
      actual
      saving
      saving_percentage
      exclude
    }
  }
`;

export const QUERY_HS_UNITS_MAP = gql`
  query getHsUnitsMap {
    get_hs_units_map {
      unit
      bb_symbol
      hs_symbol
      description
      dimensionality
    }
  }
`;

export const QUERY_DEV_ENUMERATIONS = gql`
  query getDevEnumerations($table: String, $column: String) {
    get_dev_enumerations(table: $table, column: $column) {
      id
      tables
      columns
      tag
      value
    }
  }
`;
export const QUERY_DASHBOARD_ENUMERATIONS = gql`
  query getDashboardEnumerations($table: String, $column: String) {
    dashboard_enumerations(table: $table, column: $column) {
      id
      tables
      columns
      tag
    }
  }
`;

export const QUERY_HVAC_TYPES = gql`
  query getHvacTypes(
    $building_id: Int!
    $saving_unit: String
    $unit_type: String
  ) {
    get_hvac_types(building_id: $building_id, saving_unit: $saving_unit) {
      hvac_type
    }
    get_saving_units(building_id: $building_id, unit_type: $unit_type) {
      saving_unit
    }
  }
`;

export const QUERY_VIRTUAL_METERING = gql`
  query getVirtualMeteringData(
    $building_id: Int!
    $start_date: String!
    $end_date: String!
    $hvac_type: String
    $saving_unit: String
  ) {
    virtual_metering(
      building_id: $building_id
      start_date: $start_date
      end_date: $end_date
      hvac_type: $hvac_type
      saving_unit: $saving_unit
    ) {
      id
      mv_date
      hvac_type
      saving_unit
      saving_value
      saving_percentage
      expected
      exclude
      actual
      message
    }
  }
`;

export const QUERY_VM_SAVINGS_FORECAST = gql`
  query getVMSavings(
    $building_id: Int!
    $start_date: String!
    $end_date: String!
    $hvac_type: String
    $saving_unit: String
  ) {
    vm_estimated(
      building_id: $building_id
      start_date: $start_date
      end_date: $end_date
      hvac_type: $hvac_type
      saving_unit: $saving_unit
    ) {
      saving_value
      saving_unit
    }
    vm_estimated_filtered(
      building_id: $building_id
      start_date: $start_date
      end_date: $end_date
      hvac_type: $hvac_type
      saving_unit: $saving_unit
    ) {
      saving_value
      saving_unit
    }
  }
`;

export const QUERY_WRITTEN_SYSTEMS = gql`
  query getWrittenSystems($building_id: Int!, $write_status: Int) {
    written_systems(building_id: $building_id, write_status: $write_status) {
      id
      building_id
      system_id
      label
      write_status
      updated_at
    }
  }
`;

export const QUERY_METRICS = gql`
  query getMetrics($building_id: Int!) {
    metrics_history(building_id: $building_id) {
      id
      building_id
      month
      eci
      eui
      lf
      update_time_utc
      creation_time_utc
      messages
      exclude
    }
  }
`;

export const QUERY_CLIENT_RELEASE_STATUSES = gql`
  query getClientReleaseStatuses(
    $device_id: Int!
    $building_id: Int!
    $write_status: Int
  ) {
    data_gap(device_id: $device_id) {
      device_id
      active
      upload_utc
    }

    get_algorithm_status(building_id: $building_id) {
      id
      building_id
      algorithm
      status
    }

    written_systems(building_id: $building_id, write_status: $write_status) {
      id
      building_id
      system_id
      label
      write_status
      updated_at
    }
  }
`;

export const QUERY_DATA_GAPS = gql`
  query getDataGaps($device_id: Int!) {
    data_gap(device_id: $device_id) {
      device_id
      active
      upload_utc
    }
  }
`;

export const QUERY_BUILDING_COMMAND_INTENSITY = gql`
  query getBuildingCommandIntensity(
    $building_id: Int!
    $start_date: String!
    $end_date: String!
    $offset: Int!
    $limit: Int!
    $hvac_type: String
  ) {
    building_command_intensity(
      building_id: $building_id
      start_date: $start_date
      end_date: $end_date
      offset: $offset
      limit: $limit
      hvac_type: $hvac_type
    ) {
      id
      building_id
      device_id
      system_id
      point_id
      point_value
      point_name
      algo_name
      hvac_type
      cpriority
      write_status
      unit
      addressType
      pointAddress
      creation_date_utc
      execution_date_utc
      execution_date_local
      timezone
    }
  }
`;

export const QUERY_BUILDING_COMMANDS_HVAC = gql`
  query getBuildingCommandsHvac(
    $building_id: Int!
    $start_date: String!
    $end_date: String!
    $hvac_type: String
  ) {
    get_building_commands_hvac(
      building_id: $building_id
      start_date: $start_date
      end_date: $end_date
      hvac_type: $hvac_type
    ) {
      hvac_type
      id
    }
  }
`;

export const QUERY_BUILDINGS_LIVE_MODE = gql`
  query getBuildingsLiveMode($device_ids: [Int!]!) {
    buildings_live_mode(device_ids: $device_ids) {
      id
      device_id
      algorithm_report
      status
      created_at
      updated_at
    }
  }
`;

export const QUERY_BUILDINGS_LIVE_MODE_ALL_STATUSES = gql`
  query getBuildingsLiveModeStatus($device_ids: [Int!]!) {
    buildings_live_mode_status(device_ids: $device_ids) {
      id
      device_id
      algorithm_report
      status
      created_at
      updated_at
    }
  }
`;

export const QUERY_EUTU = gql`
  query getEUTU {
    get_latest_eutu {
      version
      date_english
      date_french
    }
  }
`;

export const MUTATION_CREATE_NEXUS_USER_EUTU = gql`
  mutation createNexusUserEutu($input: create_nexus_user_eutu_input_dashboard!) {
    create_nexus_user_eutu_dashboard(input: $input) {
      id
      user_id
      version
    }
  }
`;

export const QUERY_NEXUS_BUILDINGS_BY_USER = gql`
  query getUserBuildingsByUser($user_id: String!) {
    get_nexus_user_buildings_by_user_details(user_id: $user_id) {
      building_id
      building_permissions {
        permission {
          scope
        }
      }
    }
  }
`;

export const QUERY_USER_BY_EMAIL_DETAILS = gql`
  query getUserByEmail($email: String!) {
    get_user_by_email_details(email: $email) {
      id
      first_name
      last_name
      email
      preferred_locale
      slug

      roles {
        slug

        permissions {
          scope
        }
      }

      user_eutus {
        version
      }

      user_clients {
        user_id
        client_id
        client_name
      }
    }
  }
`;

export const QUERY_DECARB_BUILDINGS = gql`
  query getDecarbBuildings($client_ids: [Int]) {
    get_decarb_buildings(client_ids: $client_ids) {
      id
      building_name
      client_id
      city
      country
      province
      latitude
      longitude
      building_label
      building_type
      postal_code
      address_line
      square_footage
    }
  }
`;

export const QUERY_DECARB_BUILDING = gql`
  query getDecarbBuilding($id: Int!) {
    get_decarb_building_by_id(id: $id) {
      id
      building_name
      client_id
      city
      country
      province
      latitude
      longitude
      building_label
      building_type
      postal_code
      address_line
      square_footage
    }
  }
`;

export const MUTATION_CREATE_DECARB_BUILDING = gql`
  mutation createDecarbBuilding($input: decarb_building_input!) {
    create_decarb_building(input: $input) {
      id
      building_name
      client_id
      city
      country
      province
      latitude
      longitude
      building_label
      building_type
      postal_code
      address_line
      square_footage
    }
  }
`;

export const MUTATION_UPDATE_DECARB_BUILDING = gql`
  mutation updateDecarbBuilding($input: decarb_building_input!, $id: Int!) {
    update_decarb_building(input: $input, id: $id) {
      id
      building_name
      client_id
      city
      country
      province
      latitude
      longitude
      building_label
      building_type
      postal_code
      address_line
      square_footage
    }
  }
`;

export const MUTATION_DELETE_DECARB_BUILDING = gql`
  mutation deleteDecarbBuilding($id: Int!) {
    delete_decarb_building(id: $id) {
      id
    }
  }
`;

export const QUERY_UPTIME = gql`
  query svUptime(
    $device_ids: [Int!]!
    $start_date: String!
    $end_date: String
  ) {
    sv_uptime(
      device_ids: $device_ids
      start_date: $start_date
      end_date: $end_date
    ) {
      building_id
      device_id
      uptime
      uptime_date_local
      report_date_utc
    }
  }
`;

export const QUERY_BUILDING_METADATA_BY_BUILDING = gql`
  query getBuildingMetadataByBuilding($building_id: Int!) {
    get_building_metadata_by_building(building_id: $building_id) {
      cur_val
    }
  }
`;
