const CryptoJS = require('crypto-js');
const { v4: uuidv4 } = require('uuid');

const secretKey = "test"; // Change this to a secure key

// Encrypt user ID
const encryptId = (userId) => {
  const ciphertext = CryptoJS.AES.encrypt(userId.toString(), secretKey).toString();
  return uuidv4().replace(/.{8}-.{4}-.{4}-.{4}-.{12}/, ciphertext); // Transform to UUID-like format
};

// Decrypt user ID
const decryptId = (uuid) => {
  const ciphertext = uuid.replace(/.{8}-.{4}-.{4}-.{4}-.{12}/, ''); // Extract the original ciphertext
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const strId = bytes.toString(CryptoJS.enc.Utf8);
  return parseInt(strId, 10);
};

module.exports = { encryptId, decryptId };
