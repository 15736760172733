<template>
  <header
    class="sticky top-0 pt-4 md:pt-0 w-auto h-24 sm:h-20 max-w-screen-4xl flex flex-row flex-shrink-0 justify-center sm:justify-end lg:justify-between bg-gray-100 md:bg-white px-4 z-40"
  >
    <!--Logic for breadcrumbs on certain pages-->
    <div
      v-if="
        $route.path.includes('/building') ||
        $route.path.includes('/decarb-buildings') ||
        $route.path.includes('/integrations')
      "
      class="hidden lg:flex items-center"
    >
      <BreadCrumb />
    </div>
    <!--Welcome message-->
    <div v-else class="hidden lg:flex items-center">
      <span class="text-lg">{{ $t("welcome") }}, {{ name }}</span>
    </div>

    <!--Logic to display either full profile Menu or the mobile screen display-->
    <div v-if="windowWidth > 768" class="items-center flex md:flex-shrink-0">
      <HeaderProfile :menuOpen="currentMenuOpen" @toggleOpen="toggleOpen" />
    </div>

    <!--Mobile Header Display-->
    <div v-else class="flex flex-wrap w-full mobile-header-wrapper">
      <div class="flex justify-between basis-full">
        <span
          ><button @click="toggleMobileMenu">
            <img src="@/assets/img/mobile_menu.png" alt="" /></button
        ></span>
        <HeaderProfile :menuOpen="currentMenuOpen" @toggleOpen="toggleOpen" />
      </div>

      <div class="basis-full flex justify-center header-img">
        <img
          class="w-60"
          src="@/assets/icons/logos/BBAI_TrademarkLogo_Grey-Red.svg"
          alt=""
        />
      </div>
    </div>
  </header>
</template>

<script>
import HeaderProfile from "@/components/HeaderProfile";
import HeaderBuildings from "@/components/HeaderBuildings";
import HeaderInfo from "@/components/HeaderInfo";
import PageTitle from "@/components/PageTitle";
import BreadCrumb from "./BreadCrumb";

export default {
  name: "Header",
  components: {
    PageTitle,
    HeaderProfile,
    HeaderBuildings,
    HeaderInfo,
    BreadCrumb,
  },
  data: () => ({
    currentMenuOpen: null,
    windowWidth: window.innerWidth,
  }),
  computed: {
    buildings() {
      return this.$store.getters["buildings"];
    },
    notificationsCount() {
      return this.$store.state.notification_count;
    },
    name() {
      if (this.$store.state.user) {
        const { user } = this.$store.state;
        if (user.first_name && user.last_name) {
          return `${user.first_name} ${user.last_name}`;
        } else {
          return this.$auth0.user.value.name;
        }
      }
      return null;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleOpen(current) {
      if (this.currentMenuOpen === current) {
        this.currentMenuOpen = null;
        return;
      }
      this.currentMenuOpen = current;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleMobileMenu() {
      this.$emit("toggleMobileMenu");
    },
  },
};
</script>

<style></style>
