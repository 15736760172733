<template>
  <div>
    <router-link
      :to="{
        name: this.$route.path.includes('decarb-buildings')
          ? 'ghg-emissions-summary'
          : 'scope-summary',
        params: { slug: hasBuildingSlug ? $route.params.slug : '239' },
      }"
      key="scope-summary"
      v-slot="{ isActive, href, navigate }">
      <a
        :href="href"
        @click="navigate"
        class="link flex items-center cursor-pointer justify-between relative text-base select-none p-1 h-12 hover:bg-black hover:text-white"
        :class="[isActive ? 'active text-white bg-black delay-0' : '']">
        <div class="flex">
          <!-- <img class="icon shrink-0 mx-2" :src="getImgUrl(link.icon)"> -->
          <span v-if="!collapsed" class="ml-10 font-normal"> Summary </span>
          <!-- <div v-if="collapsed" class="icon-label text-base items-center flex p-4">{{ $t(link.label) }}</div> -->
        </div>
      </a>
    </router-link>
    <router-link
      :to="{
        name: this.$route.path.includes('decarb-buildings')
          ? 'ghg-emissions-scope1'
          : 'scope-scope1',
        params: { slug: hasBuildingSlug ? $route.params.slug : '239' },
      }"
      key="scope-scope1"
      v-slot="{ isActive, href, navigate }">
      <a
        :href="href"
        @click="navigate"
        class="link flex items-center cursor-pointer justify-between relative text-base select-none p-1 h-12 hover:bg-black hover:text-white"
        :class="[isActive ? 'active text-white bg-black delay-0' : '']">
        <div class="flex">
          <!-- <img class="icon shrink-0 mx-2" :src="getImgUrl(link.icon)"> -->
          <span v-if="!collapsed" class="ml-10 font-normal"> Scope 1 </span>
          <!-- <div v-if="collapsed" class="icon-label text-base items-center flex p-4">{{ $t(link.label) }}</div> -->
        </div>
      </a>
    </router-link>
    <router-link
      :to="{
        name: this.$route.path.includes('decarb-buildings')
          ? 'ghg-emissions-scope2'
          : 'scope-scope2',
        params: { slug: hasBuildingSlug ? $route.params.slug : '239' },
      }"
      key="scope-scope2"
      v-slot="{ isActive, href, navigate }">
      <a
        :href="href"
        @click="navigate"
        class="link flex items-center cursor-pointer justify-between relative text-base select-none p-1 h-12 hover:bg-black hover:text-white"
        :class="[isActive ? 'active text-white bg-black delay-0' : '']">
        <div class="flex">
          <!-- <img class="icon shrink-0 mx-2" :src="getImgUrl(link.icon)"> -->
          <span v-if="!collapsed" class="ml-10 font-normal"> Scope 2 </span>
          <!-- <div v-if="collapsed" class="icon-label text-base items-center flex p-4">{{ $t(link.label) }}</div> -->
        </div>
      </a>
    </router-link>
    <router-link
      :to="{
        name: this.$route.path.includes('decarb-buildings')
          ? 'ghg-emissions-reporting'
          : 'emissions-reporting',
        params: { slug: hasBuildingSlug ? $route.params.slug : '239' },
      }"
      key="emissions-reporting"
      v-slot="{ isActive, href, navigate }">
      <a
        :href="href"
        @click="navigate"
        class="link flex items-center cursor-pointer justify-between relative text-base select-none p-1 h-12 hover:bg-black hover:text-white"
        :class="[isActive ? 'active text-white bg-black delay-0' : '']">
        <div class="flex">
          <!-- <img class="icon shrink-0 mx-2" :src="getImgUrl(link.icon)"> -->
          <span v-if="!collapsed" class="ml-10 font-normal"> FAQ </span>
          <!-- <div v-if="collapsed" class="icon-label text-base items-center flex p-4">{{ $t(link.label) }}</div> -->
        </div>
      </a>
    </router-link>
  </div>
</template>

<script>
import { collapsed } from './state';
export default {
  setup(props) {
    return { collapsed };
  },
  data() {
    return {
      showSubMenu: false,
    };
  },
  computed: {
    hasBuildingSlug() {
      return (
        this.$route.params &&
        this.$route.params.slug &&
        this.$route.path.includes('buildings')
      );
    },
  },
  methods: {
    getImgUrl: function (img) {
      return require('@/assets/icons/' + img);
    },
  },
};
</script>

<style scoped>
.link {
  border-radius: 0.5rem;
}
.link:hover img {
  filter: invert();
}

.active {
  background-color: black;
  color: white;
}
.active img {
  filter: invert();
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease;
  max-height: 400px;
}

.expand-enter-from,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
