<template>
  <div id="header-profile" class="items-center flex">
    <div class="userDd" trigger="click">
      <div
        v-if="$auth0.isAuthenticated"
        @click="toggleOpen()"
        class="userDrop justify-between cursor-pointer select-none"
      >
        <img
          :src="$auth0.user.value.picture"
          width="40"
          height="40"
          loading="lazy"
          class="md:mr-2"
        />
        <div v-if="!isMobile" class="userinfo relative">
          <div class="userinfo_inf">
            <div class="font-semibold">{{ name }}</div>
            <!-- <div>Building Owner</div> -->
          </div>
        </div>
        <span v-if="!isMobile" class="collapse-icon">
          <img
            class="w-6 h-6 max-w-none"
            src="@/assets/icons/expand_more_black.svg"
            :class="{ 'rotate-180': isOpen }"
          />
        </span>
      </div>
      <div v-else>
        <svg class="h-6 w-6">
          <use href="#profile"></use>
        </svg>
      </div>
      <transition
        enter-active-class="transition-all duration-100 ease-out"
        leave-active-class="transition-all duration-100 ease-in"
        enter-class="opacity-0 scale-75"
        enter-to-class="opacity-100 scale-100"
        leave-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-75"
      >
        <div
          v-click-outside="toggleOpen"
          v-if="isOpen"
          class="dropdown-content flex flex-col bg-white origin-top-right rounded w-96 h-fit mt-1 mr-1 justify-between drop-shadow-lg"
        >
          <DropdownContent v-if="$auth0.isAuthenticated" />
          <button
            v-if="$auth0.isAuthenticated"
            @click="logout"
            class="bg-white hover:bg-black hover:text-white transition-colors duration-300 py-2 rounded-md border inline-flex items-center justify-center logout-btn"
            :class="[{ 'text-black': !abbUser }]"
          >
            <img src="@/assets/icons/logout_black.svg" class="mr-2" alt="" />
            {{ $t("logout") }}
          </button>
          <button
            v-if="!$auth0.isAuthenticated"
            @click="login"
            class="bg-white hover:bg-black hover:text-white transition-colors duration-300 py-2 rounded-md"
          >
            {{ $t("login") }}
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { isMoment } from "moment";
import DropdownContent from "./DropdownContent";
export default {
  name: "HeaderProfile",
  components: { DropdownContent },
  props: ["menuOpen"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    name() {
      if (this.$store.state.user) {
        const { user } = this.$store.state;
        if (user.first_name && user.last_name) {
          return `${user.first_name} ${user.last_name}`;
        } else {
          return this.$auth0.user.value.name;
        }
      }
      return null;
    },
  },
  methods: {
    login() {
      this.$auth0.loginWithRedirect();
    },
    logout() {
      this.$auth0.logout({ returnTo: window.location.origin });
    },
    onClickOutside() {
      this.$emit("toggleOpen", "");
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.userDrop {
  display: flex;
  align-items: center;
}
.userDrop img {
  border-radius: 50%;
  vertical-align: middle;
}
.userinfo {
  display: flex;
  align-items: center;
}
.userinfo i {
  margin-left: 15px;
  color: #000;
  font-weight: bold;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  padding: 1.25rem;
  background-color: white;
  list-style: none;
}

.logout-btn:hover img {
  filter: invert(100);
}
</style>
