export const defaults = {
  colors: {
    base: "#e40e20",
  },
};

export const dataviz = {
  // defaults => ie: colors
  // bar...
  // line...
  radial: {
    default: {
      options: {
        type: "slider",
        focusAt: "center",
        perView: 4,
        gap: 0,
        rewind: false,
        animationDuration: 1000,
        breakpoints: {
          1100: {
            perView: 1,
          },
          1500: {
            perView: 2,
          },
          1800: {
            perView: 3,
          },
        },
      },
    },
  },
  columns: {
    options: {
      type: "slider",
      perView: 4,
      gap: 64,
      startAt: 0,
      // rewind: false,
      animationDuration: 1000,
      breakpoints: {
        767: {
          perView: 1,
        },
        1100: {
          perView: 2,
        },
        1279: {
          perView: 3,
        },
        1550: {
          perView: 2,
        },
        1700: {
          perView: 3,
        },
      },
    },
  },
};

export const weatherCodes = {
  0: "unknown",
  1000: { label: "clear", icon: { day: "clear_day", night: "clear_night" } },
  1100: {
    label: "mostly-clear",
    icon: { day: "mostly_clear_day", night: "mostly_clear_night" },
  },
  1101: {
    label: "partly-cloudy",
    icon: { day: "partly_cloudy_day", night: "partly_cloudy_night" },
  },
  1102: {
    label: "mostly-cloudy",
    icon: { day: "mostly_cloudy", night: "mostly_cloudy" },
  },
  1001: { label: "cloudy", icon: { day: "cloudy", night: "cloudy" } },
  2000: { label: "fog", icon: { day: "fog", night: "fog" } },
  2100: { label: "light-fog", icon: { day: "fog_light", night: "fog_light" } },
  4000: { label: "drizzle", icon: { day: "drizzle", night: "drizzle" } },
  4001: { label: "rain", icon: { day: "rain", night: "rain" } },
  4200: {
    label: "light-rain",
    icon: { day: "rain_light", night: "rain_light" },
  },
  4201: {
    label: "heavy-rain",
    icon: { day: "rain_heavy", night: "rain_heavy" },
  },
  5000: { label: "snow", icon: { day: "snow", night: "snow" } },
  5001: { label: "flurries", icon: { day: "flurries", night: "flurries" } },
  5100: {
    label: "light-snow",
    icon: { day: "snow_light", night: "snow_light" },
  },
  5101: {
    label: "heavy-snow",
    icon: { day: "snow_heavy", night: "snow_heavy" },
  },
  6000: {
    label: "freezing-drizzle",
    icon: { day: "freezing_drizzle", night: "freezing_drizzle" },
  },
  6001: {
    label: "freezing-rain",
    icon: { day: "freezing_rain", night: "freezing_rain" },
  },
  6200: {
    label: "light-freezing-rain",
    icon: { day: "freezing_rain_light", night: "freezing_rain_light" },
  },
  6201: {
    label: "heavy-freezing-rain",
    icon: { day: "freezing_rain_heavy", night: "freezing_rain_heavy" },
  },
  7000: {
    label: "ice-pellets",
    icon: { day: "ice_pellets", night: "ice_pellets" },
  },
  7101: {
    label: "heavy-ice-pellets",
    icon: { day: "ice_pellets_heavy", night: "ice_pellets_heavy" },
  },
  7102: {
    label: "light-ice-pellets",
    icon: { day: "ice_pellets_light", night: "ice_pellets_light" },
  },
  8000: { label: "thunderstorm", icon: { day: "tstorm", night: "tstorm" } },
};

export const devLabSystems = {
  active: [
    {
      system_id: "RTU1",
      write_status: 1,
    },
    {
      system_id: "RTU2",
      write_status: 1,
    },
    {
      system_id: "RTU3",
      write_status: 1,
    },
    {
      system_id: "VAV1-1",
      write_status: 1,
    },
    {
      system_id: "VAV1-2",
      write_status: 1,
    },
    {
      system_id: "VAV1-3",
      write_status: 1,
    },
    {
      system_id: "VAV1-4",
      write_status: 1,
    },
    {
      system_id: "VAV1-5",
      write_status: 1,
    },
    {
      system_id: "VAV1-6",
      write_status: 1,
    },
    {
      system_id: "VAV1-7",
      write_status: 1,
    },
    {
      system_id: "VAV1-8",
      write_status: 1,
    },
    {
      system_id: "VAV2-1",
      write_status: 1,
    },
    {
      system_id: "VAV2-2",
      write_status: 1,
    },
    {
      system_id: "VAV2-3",
      write_status: 1,
    },
    {
      system_id: "VAV2-4",
      write_status: 1,
    },
    {
      system_id: "VAV2-5",
      write_status: 1,
    },
    {
      system_id: "VAV2-6",
      write_status: 1,
    },
    {
      system_id: "VAV2-7",
      write_status: 1,
    },
    {
      system_id: "VAV2-8",
      write_status: 1,
    },
    {
      system_id: "VAV3-1",
      write_status: 1,
    },
    {
      system_id: "VAV3-2",
      write_status: 1,
    },
    {
      system_id: "VAV3-3",
      write_status: 1,
    },
    {
      system_id: "VAV3-4",
      write_status: 1,
    },
    {
      system_id: "VAV3-5",
      write_status: 1,
    },
    {
      system_id: "VAV3-6",
      write_status: 1,
    },
    {
      system_id: "VAV3-7",
      write_status: 1,
    },
    {
      system_id: "VAV3-8",
      write_status: 1,
    },
  ],
  inactive: [
    {
      system_id: "RTU4",
      write_status: 0,
    },

    {
      system_id: "VAV4-1",
      write_status: 0,
    },
    {
      system_id: "VAV4-2",
      write_status: 0,
    },
    {
      system_id: "VAV4-3",
      write_status: 0,
    },
    {
      system_id: "VAV4-4",
      write_status: 0,
    },
    {
      system_id: "VAV4-5",
      write_status: 0,
    },
    {
      system_id: "VAV4-6",
      write_status: 0,
    },
    {
      system_id: "VAV4-7",
      write_status: 0,
    },
    {
      system_id: "VAV4-8",
      write_status: 0,
    },
  ],
};

export const keys = {
  virtual_metering: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving_unit",
    saving_value: "saving_value",
    emission: "emission",
    name: "virtual-metering",
    hideSinceImplementationValue: false,
  },

  electricity: {
    expected: "expected_consumption",
    actual: "actual_consumption",
    saving_percent: "saving_percentage",
    saving_unit: "saving_kwh",
    unit: "kWh",
    label: "electricity_savings",
    name: "electricity",
    hideSinceImplementationValue: false,
  },
  gas: {
    expected: "expected_consumption",
    actual: "actual_consumption",
    saving_percent: "saving_percentage",
    saving_unit: "saving_volume",
    unit: "m³",
    label: "gas_savings",
    name: "gas",
    hideSinceImplementationValue: false,
  },
  power: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving",
    unit: "kW",
    label: "power_savings",
    name: "power",
    hideSinceImplementationValue: false,
  },
  monetary: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving",
    unit: "$",
    label: "monetary_savings",
    name: "monetary",
    hideSinceImplementationValue: false,
  },
  monetary_electricity: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving",
    unit: "kWh",
    label: "electricity_savings",
    name: "electricity",
    hideSinceImplementationValue: false,
  },
  monetary_gas: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving",
    unit: "m³",
    label: "gas_savings",
    name: "gas",
    hideSinceImplementationValue: false,
  },
  monetary_power: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving",
    unit: "kW",
    label: "power_savings",
    name: "power",
    hideSinceImplementationValue: false,
  },
  emissions: {
    expected: "expected",
    actual: "actual",
    saving_percent: "saving_percentage",
    saving_unit: "saving",
    label: "emissions_savings",
    name: "emissions",
    unit: "TCO2eq",
    hideSinceImplementationValue: true,
  },
  comfort: {
    label: "comfort",
    name: "comfort",
    unit: "%",
    hideSinceImplementationValue: true,
  },
};

export const unitMeasurements = {
  tCO2eq: {
    availableConversions: [
      {
        name: "tC02eq",
        calculation: 1,
        min: 0,
        max: 1,
        step: 0.01,
        default: 0.15,
      },
    ],
  },

  kWh: {
    availableConversions: [
      {
        name: "kWh",
        calculation: 1,
        min: 0,
        max: 1,
        step: 0.01,
        default: 0.15,
        unitAmount: 1,
      },
    ],
  },
  m3: {
    availableConversions: [
      {
        name: "m3",
        calculation: 1,
        min: 0.1,
        max: 0.5,
        step: 0.01,
        default: 0.25,
        unitAmount: 1,
      },
      {
        name: "mbtu",
        calculation: 38.86,
        min: 0,
        max: 100,
        step: 0.1,
        default: 5,
        unitAmount: 1000,
      },
    ],
  },
};

export const decarb_schema = (fuelOpt) => [
  {
    $el: "div",
    if: '$type === "Stationary combustion"',
    children: [
      {
        $cmp: "FormKit",
        if: "$type",
        props: {
          type: "select",
          id: "st_fuel",
          key: "st_fuel",
          name: "fuel",
          label: "Fuel",
          outerClass: "w-full",
          placeholder: "Select fuel",
          validation: "required",
          inputClass: "form_inputs_style",
          labelClass: "mb-4 font-semibold asterisks",
          options: fuelOpt,
        },
      },
      {
        $el: "div",
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              id: "st_amount",
              key: "st_amount",
              name: "amount",
              label: "Fuel amount",
              outerClass: "w-3/5",
              validation: "required",
              inputClass: "form_inputs_style amount_style",
              labelClass: "w-1/2 font-semibold",
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              id: "st_unit",
              key: "st_unit",
              name: "unit",
              label: "Unit of fuel",
              outerClass: "w-2/5",
              placeholder: "Select unit",
              options: "$unit_options",
              validation: "required",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold asterisks",
            },
          },
        ],
      },
    ],
  },
  {
    $el: "div",
    if: '$type === "Mobile combustion"',
    children: [
      {
        $cmp: "FormKit",
        props: {
          type: "select",
          id: "mode_of_transport",
          key: "mode_of_transport",
          name: "mode_of_transport",
          label: "Mode of transport",
          placeholder: "Select mode of transport",
          /*validation: '$type === "Mobile combustion"' ? 'required' : "",
          validationVisibility: '$type === "Mobile combustion"' ? 'dirty' : "",*/
          inputClass: "form_inputs_style",
          labelClass: "mb-4 font-semibold asterisks",
          options: {
            if: '$type === "Mobile combustion"',
            then: "$modes_options",
            else: [],
          },
        },
      },
      {
        $cmp: "FormKit",
        props: {
          type: "select",
          id: "vehicle_type",
          key: "vehicle_type",
          name: "vehicle_type",
          label: "Vehicle type",
          placeholder: "Select vehicle type",
          validation: '$type === "Mobile combustion"' ? "required" : "",
          inputClass: "form_inputs_style",
          labelClass: "mb-4 font-semibold asterisks",
          options: {
            if: '$type === "Mobile combustion"',
            then: "$vehicles_options",
            else: [],
          },
        },
      },
      {
        $cmp: "FormKit",
        if: '$mode_of_transport === "Road" && ($vehicle_type === "Passenger Car" || $vehicle_type === "Vans, Pickup Trucks, & SUVs" || $vehicle_type === "Heavy-Duty Vehicle" || $vehicle_type === "Motorcycle") || $vehicle_type === "Light Truck || || $vehicle_type === "Medium and Heavy-Duty Vehicle")")',
        props: {
          type: "select",
          name: "model",
          id: "model",
          key: "model",
          label: "Model Year",
          placeholder: "Select model",
          options: "$model_options",
          validation: '$type === "Mobile combustion"' ? "required" : "",
          inputClass: "form_inputs_style",
          labelClass: "mb-4 font-semibold",
        },
      },
      {
        $cmp: "FormKit",
        props: {
          type: "select",
          name: "fuel",
          id: "mob_fuel",
          key: "mob_fuel",
          label: "Fuel",
          placeholder: "Select fuel",
          inputClass: "form_inputs_style",
          labelClass: "mb-4 font-semibold asterisks",
          options: fuelOpt,
        },
      },
      {
        $el: "div",
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              name: "amount",
              id: "mob_amount",
              key: "mob_amount",
              label: "Fuel amount",
              outerClass: "w-3/5",
              validation: "required",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold asterisks",
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              name: "unit",
              id: "mob_unit",
              key: "mob_unit",
              label: "Unit of fuel",
              outerClass: "w-2/5",
              placeholder: "Select unit",
              options: "$unit_options",
              validation: "required",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold asterisks",
            },
          },
        ],
      },
      {
        $el: "div",
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            if: '$mode_of_transport === "Road"',
            props: {
              type: "number",
              name: "distance",
              id: "distance",
              key: "distance",
              label: "Distance travelled",
              outerClass: "w-3/5",
              validation: '$type === "Mobile combustion"' ? "required" : "",
              inputClass: "form_inputs_style amount_style",
              labelClass: "w-1/2 font-semibold",
            },
          },
          {
            $cmp: "FormKit",
            if: '$mode_of_transport === "Road"',
            props: {
              type: "select",
              name: "unit_2",
              id: "mob_unit_2",
              key: "mob_unit_2",
              label: "Unit of measurement",
              outerClass: "w-2/5",
              placeholder: "Select unit",
              options: {
                Kilometer: "Kilometer",
                Mile: "Mile",
              },
              validation: '$type === "Mobile combustion"' ? "required" : "",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold asterisks",
            },
          },
        ],
      },
    ],
  },
  {
    $el: "div",
    if: '$type === "Fugitive combustion"',
    children: [
      /*{
        $cmp: 'FormKit',
        props: {
          type: 'select',
          key: 'refrigerant_type',
          id: 'refrigerant_type',
          name: 'refrigerant_type',
          label: 'Refrigerant emissions activity',
          placeholder: 'Select Refrigerant emissions activity',
          options: [
            { value: "Installation emissions", label: 'Installation emissions' },
            { value: "Operating emissions", label: 'Operating emissions' },
            { value: "Disposal emissions", label: 'Disposal emissions' },
          ],
          inputClass: "form_inputs_style amount_style",
          labelClass: "font-semibold",
          validation: 'required',
          validationVisibility: 'dirty',
        }
      },*/
      {
        $cmp: "FormKit",
        props: {
          type: "radio",
          key: "refrigerant_type",
          id: "refrigerant_type",
          name: "refrigerant_type",
          label: "Refrigerant emissions activity",
          options: [
            {
              value: "Installation emissions",
              label: "Installation emissions",
            },
            { value: "Operating emissions", label: "Operating emissions" },
            { value: "Disposal emissions", label: "Disposal emissions" },
          ],
          legendClass: "font-semibold",
          wrapperClass: "flex",
          labelClass: "pl-2 has-tip",
          validation: "required",
        },
      },
      {
        $cmp: "FormKit",
        props: {
          type: "text",
          id: "refrigerant_description",
          key: "refrigerant_description",
          name: "refrigerant_description",
          label: "Description",
          inputClass: "form_inputs_style amount_style",
          labelClass: "w-1/2 font-semibold asterisks",
          validation: "required",
        },
      },
      {
        $cmp: "FormKit",
        props: {
          type: "select",
          id: "refrigerant",
          key: "refrigerant",
          name: "refrigerant",
          label: "Refrigerant",
          inputClass: "form_inputs_style amount_style",
          labelClass: "w-1/2 font-semibold asterisks",
          placeholder: "Select refrigerant",
          options: "$refrigerant_options",
          validation: "required",
        },
      },
      {
        $el: "div",
        if: '$refrigerant_type === "Installation emissions"',
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              id: "PN",
              key: "PN",
              name: "PN",
              label: "Purchases of refrigerant used to charge new equipment",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-3/4",
              validation: '$: "required|number|min:1|max:" + $CN',
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              name: "unit",
              id: "fug_inst_unit",
              key: "fug_inst_unit",
              label: "Unit",
              placeholder: "Unit",
              options: [{ value: "kg", label: "kg" }],
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold asterisks",
              outerClass: "w-1/4",
              validation: "required",
            },
          },
        ],
      },
      {
        $el: "div",
        if: '$refrigerant_type === "Installation emissions"',
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              id: "CN",
              key: "CN",
              name: "CN",
              label: "Total refrigerant capacity of the new equipment",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-3/4",
              validation: "required|number|min:1",
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              name: "unit_2",
              id: "fug_inst_unit_2",
              key: "fug_inst_unit_2",
              label: "Unit",
              placeholder: "Unit",
              options: [{ value: "kg", label: "kg" }],
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold asterisks",
              outerClass: "w-1/4",
              validation: "required",
            },
          },
        ],
      },
      {
        $el: "div",
        if: '$refrigerant_type === "Operating emissions"',
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              id: "PS",
              key: "PS",
              name: "PS",
              label: "Purchases of refrigerant used to service equipment",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-3/4",
              validation: "required|number|min:1",
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              name: "unit",
              id: "fug_op_unit",
              key: "fug_op_unit",
              label: "Unit",
              placeholder: "Unit",
              options: [{ value: "kg", label: "kg" }],
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-1/4",
              validation: "required",
            },
          },
        ],
      },
      {
        $el: "div",
        if: '$refrigerant_type === "Disposal emissions"',
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              name: "CD",
              id: "CD",
              key: "CD",
              label: "Total refrigerant capacity of retiring equipment",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-3/4",
              validation: "required|number|min:1",
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              name: "unit",
              id: "fug_dis_unit",
              key: "fug_dis_unit",
              label: "Unit",
              placeholder: "Unit",
              options: [{ value: "kg", label: "kg" }],
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-1/4",
              validation: "required",
            },
          },
        ],
      },
      {
        $el: "div",
        if: '$refrigerant_type === "Disposal emissions"',
        attrs: {
          style: {
            display: "flex",
          },
        },
        children: [
          {
            $cmp: "FormKit",
            props: {
              type: "number",
              name: "RD",
              id: "RD",
              key: "RD",
              label: "Refrigerant recovered from retiring equipment",
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-3/4",
              validation: '$: "required|number|min:1|max:" + $CD',
            },
          },
          {
            $cmp: "FormKit",
            props: {
              type: "select",
              name: "unit_2",
              id: "fug_dis_unit_2",
              key: "fug_dis_unit_2",
              label: "Unit",
              placeholder: "Unit",
              options: [{ value: "kg", label: "kg" }],
              inputClass: "form_inputs_style amount_style",
              labelClass: "font-semibold",
              outerClass: "w-1/4",
              validation: "required",
            },
          },
        ],
      },
    ],
  },
];
